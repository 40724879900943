import React, { useState, useEffect, useContext } from 'react';
import {
  Table, TableBody, TableHead, TableContainer, Paper, Typography, Box, Tooltip, Switch, TableSortLabel, Button, TableCell, TableRow, TablePagination, TextField, IconButton, Autocomplete
} from '@mui/material';
import { Delete as DeleteIcon, ArrowDownward as ArrowDownwardIcon, ArrowUpward as ArrowUpwardIcon, Edit as EditIcon } from '@mui/icons-material';
import axios from 'axios';
import { useAuth } from './AuthContext';
import { SessionContext } from './SessionContext';
import { AccountContext } from './AccountContext';
import {  Serializer } from "@wharfkit/antelope"
import Modal from './Modal';
import { useFormik } from 'formik';
import * as yup from 'yup';

const TokenPool = () => {
  const { session, loginWallet } = useContext(SessionContext);
  const { account } = useContext(AccountContext);
  const { authState, logout } = useAuth();
  const [pools, setPools] = useState([]);
  const [editRateId, setEditRateId] = useState(null);
  const [editRateValue, setEditRateValue] = useState('');
  const [editMinRedeemId, setEditMinRedeemId] = useState(null);
  const [editMinRedeemValue, setEditMinRedeemValue] = useState('');
  const [open, setOpen] = useState(false);
  const [modalType, setModalType] = useState('');
  const [selectedPool, setSelectedPool] = useState(null);
  const [userBalance, setUserBalance] = useState(0);
  const [depositAmount, setDepositAmount] = useState('');
  const [withdrawAmount, setWithdrawAmount] = useState('');
  const [withdrawWallet, setWithdrawWallet] = useState('');
  const [editingPool, setEditingPool] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [message, setMessage] = useState('');
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('token_id');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [totalPools, setTotalPools] = useState(0);
  const [symbols, setSymbols] = useState([]);
  const [redemptionDisplay, setRedemptionDisplay] = useState('');
  const [channelTokenName, setChannelTokenName] = useState('loyalty tokens');
  const [selectedTokenDecimals, setSelectedTokenDecimals] = useState(2);
  const [selectedTokenSymbol, setSelectedTokenSymbol] = useState('');
  const [isLoggingIn, setIsLoggingIn] = useState(false);

  useEffect(() => {
    fetchTotalPools();
    fetchPools();
  }, [orderBy, order, page, rowsPerPage]);

  const fetchChannelSettings = async () => {
    try {
      const response = await axios.get('/api/channel-settings?setting_type=loyalty');
      const settings = response.data.data;
      const tokenNameSetting = settings.find(setting => setting.setting_key === 'channel_token_name');
      if (tokenNameSetting) {
        setChannelTokenName(tokenNameSetting.setting_value);
      }
    } catch (error) {
      console.error('Error fetching channel settings:', error);
    }
  };

  const fetchPools = async () => {
    try {
      const response = await axios.get('/api/token-pools', {
        params: { sort_by: orderBy, sort_order: order, limit: rowsPerPage, offset: page * rowsPerPage },
      });
      setPools(response.data.data);
    } catch (error) {
      console.error('Error fetching pools:', error);
    }
  };

  const fetchTotalPools = async () => {
    try {
      const response = await axios.get('/api/token-pools/count');
      setTotalPools(response.data.total_count);
    } catch (error) {
      console.error('Error fetching total pools count:', error);
    }
  };

  const refreshPools = async () => {
    try {
      const response = await axios.post('/api/token-pool/refresh');
      if (response.data.success) {
        await fetchPools();
        await fetchTotalPools();
      } else {
        console.error('Error refreshing pools:', response.data.message);
      }
    } catch (error) {
      console.error('Error refreshing pools:', error);
    }
  };

  const fetchSymbols = async () => {
    try {
      const response = await axios.get('/api/tokens');
      const symbolOptions = response.data.map((token) => ({
        value: token.token_id,
        label: `${token.symbol} (${token.contract})`,
        symbol: token.symbol,
        contract: token.contract,
        decimals: token.decimals,
      }));
      setSymbols(symbolOptions);
    } catch (error) {
      console.error('Error fetching symbols:', error);
    }
  };

  const handleAddPool = async () => {
    await fetchSymbols();
    await fetchChannelSettings();
    setModalType('create');
    setOpen(true);
    formik.resetForm(); // Reset the form when opening the create modal
    setRedemptionDisplay(''); // Reset the redemption display
    setSelectedTokenSymbol(''); // Reset the selected token symbol
  };

  const handleEditPool = async (pool) => {
    await fetchSymbols();
    await fetchChannelSettings().then(() => {
      setEditingPool(pool);
      setSelectedPool(pool);
      setModalType('edit');
      setOpen(true);

      formikEdit.setValues({
        rate: pool.rate,
        minRedeem: pool.min_redeem,
        maxRedeem: pool.max_redeem,
        cooldown: pool.cooldown,
        is_active: pool.is_active,
      });

      setSelectedTokenDecimals(pool.decimals);
      setSelectedTokenSymbol(pool.symbol);
      updateRedemptionDisplay(pool.rate, pool.min_redeem, pool.decimals, pool.symbol);
    });
  };

  const handleActiveChange = async (pool) => {
    try {
      const updatedPool = { ...pool, is_active: !pool.is_active };
      await axios.post('/api/token-pools/update', {
        token_id: pool.token_id,
        is_active: updatedPool.is_active,
        rate: pool.rate,
        min_redeem: pool.min_redeem,
        max_redeem: pool.max_redeem,
        cooldown: pool.cooldown,
      });
      setPools((prevPools) =>
        prevPools.map((p) => (p.token_id === pool.token_id ? updatedPool : p))
      );
    } catch (error) {
      console.error('Error updating active status:', error);
    }
  };

  const handleDeletePool = async (pool) => {
    try {
      await axios.post('/api/token-pool/delete', {
        token_id: pool.token_id,
      });
      setOpen(true);
      setIsSuccess(true);
      setMessage('Pool deleted successfully!');
      fetchPools();
      fetchTotalPools();
    } catch (error) {
      setIsError(true);
      setMessage('Error deleting pool. Please try again.');
      console.error('Error deleting pool:', error);
    }
  };

  const handleWithdraw = async (pool) => {
    setLoading(true);
    setIsSuccess(false);
    setIsError(false);
  
    if (!authState || !authState.twitch_id) {
      console.error('Not authenticated, please login again.');
      return;
    }
    const memo = `withdraw|${authState.twitch_id}`;
  
    try {
      const response = await axios.post('/api/transfer-token', {
        contract: pool.contract,
        memo: memo,
        to: withdrawWallet,
        quantity: withdrawAmount,
        symbol: pool.symbol
      });
  
      if (response.data.success) {
        setIsSuccess(true);
        // Refresh token pools
        await fetchPools();
        handleClose();
      } else {
        setIsError(true);
        setMessage(response.data.message || 'Error withdrawing tokens. Please try again.');
      }
    } catch (error) {
      setIsError(true);
      setMessage('Error withdrawing tokens. Please try again.');
      console.error('Error withdrawing tokens:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleDeposit = async (pool) => {
    if (!session) {
      setIsLoggingIn(true);
      await loginWallet();
      setIsLoggingIn(false);
      if (!session) {
        return;
      }
    }

    setSelectedPool(pool);
    setModalType('deposit');
    setOpen(true);

    if (!account) {
      console.error("No account found. Cannot fetch balance.");
      return;
    }

    try {
      const response = await account.client.v1.chain.get_table_rows({
        code: pool.contract,
        scope: session.actor.toString(),
        table: 'accounts',
        limit: 1,
      });

      if (response.rows.length > 0) {
        const fetchedBalance = response.rows[0].balance;

        const numericBalance = parseFloat(fetchedBalance.split(' ')[0]);
        setUserBalance(numericBalance);
      } else {
        setUserBalance(0);
      }
    } catch (error) {
      setUserBalance(0);
    }
  };

  const handleDepositSubmit = async () => {
    if (parseFloat(depositAmount) <= 0 || parseFloat(depositAmount) > parseFloat(userBalance)) {
      setMessage('Invalid deposit amount.');
      setIsError(true);
      return;
    }

    if (!authState || !authState.twitch_id) {
      console.error('Not authenticated, please login again.');
      return;
    }
    const memo = `deposit|${authState.twitch_id}`;

    try {
      setLoading(true);
      const paddedDepositAmount = parseFloat(depositAmount).toFixed(selectedPool.decimals);
      const action = {
        account: selectedPool.contract,
        name: 'transfer',
        authorization: [session.permissionLevel],
        data: {
          from: session.actor.toString(),
          to: 'redeem.sb',
          quantity: `${paddedDepositAmount} ${selectedPool.symbol}`,
          memo: memo,
        },
      };

      const result = await session.transact({ action });
      const serialisedTransaction = Serializer.objectify(result);

      if (result) {
        const transactionId = serialisedTransaction.response?.processed?.id;
        const actionTrace = serialisedTransaction.response.processed.action_traces.find(
          (trace) => 
            trace.act.name === 'transfer' && 
            trace.act.data.memo === memo &&
            trace.act.data.quantity === `${paddedDepositAmount} ${selectedPool.symbol}` &&
            trace.act.account === selectedPool.contract
        );

        if (actionTrace) {
          await refreshPools();
          await fetchPools();
          await fetchTotalPools();
          setIsSuccess(true);
          setMessage(`${parseFloat(depositAmount).toLocaleString()} ${selectedPool.symbol} deposited successfully!<br/><a href="https://waxblock.io/transaction/${transactionId}" target="_blank" rel="noopener noreferrer">View the transaction</a>`);
        } else {
          setIsError(true);
          setMessage('Transaction completed but action trace not found.');
        }
      }
    } catch (error) {
      console.error('Deposit transaction failed', error);
      setIsError(true);
      setMessage('Error processing deposit transaction. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleBalanceClick = () => {
    setDepositAmount(userBalance);
  };

  const validationSchema = yup.object({
    symbol: yup.object().nullable().required('Symbol is required'),
    rate: yup
      .number('Enter a redemption rate')
      .min(0.01, 'Redemption rate must be at least 0.01%')
      .test('decimal-places', 'Redemption rate can have up to 2 decimal places', (value) =>
        /^\d+(\.\d{1,2})?$/.test(value)
      )
      .required('Redemption rate is required'),
    min_redeem: yup
      .number('Enter a minimum redeem amount')
      .integer('Minimum redeem must be a whole number')
      .min(1, 'Minimum redeem must be at least 1')
      .required('Minimum redeem is required'),
    max_redeem: yup
      .number('Enter a maximum redeem amount')
      .integer('Maximum redeem must be a whole number')
      .min(0, 'Maximum redeem must be at least 0 (0 being unlimited)')
      .required('Maximum redeem is required'),
    cooldown: yup
      .number('Enter a cooldown for redeeming in minutes')
      .integer('Cooldown must be a whole number')
      .min(0, 'Cooldown must be at least 0 minutes')
      .required('Cooldown is required'),
      });

  const formik = useFormik({
    initialValues: {
      symbol: null,
      rate: '',
      min_redeem: '',
      max_redeem: '',
      cooldown: '',
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { resetForm }) => {
      setLoading(true);
      setIsSuccess(false);
      setIsError(false);
    
      if (!values.symbol || !values.rate || !values.min_redeem || !values.max_redeem || !values.cooldown) {
        setLoading(false);
        return;
      }
    
      const redemptionAmount =
        Math.floor(values.min_redeem * (values.rate / 100) * Math.pow(10, selectedTokenDecimals)) /
        Math.pow(10, selectedTokenDecimals);
    
      try {
        const response = await axios.post('/api/token-pool', {
          token_id: values.symbol.value,
          symbol: values.symbol.label.split(' ')[0],
          contract: values.symbol.label.split(' ')[1].slice(1, -1),
          rate: values.rate,
          min_redeem: values.min_redeem,
          max_redeem: values.max_redeem,
          cooldown: values.cooldown,
          is_active: false,
        });
    
        setLoading(false);
        if (response.data.success) {
          setIsSuccess(true);
          setMessage('Pool created successfully!');
          fetchPools();
          fetchTotalPools();
          resetForm();
          setRedemptionDisplay('');
        } else {
          setIsError(true);
          setMessage(response.data.message || 'Error creating pool. Please try again later.');
        }
      } catch (error) {
        setLoading(false);
        setIsError(true);
        setMessage(error.response?.data?.message || 'Error creating pool. Please try again later.');
      }
    },
  });

  const formikEdit = useFormik({
    initialValues: {
      rate: '',
      minRedeem: '',
      maxRedeem: '',
      cooldown: '',
      is_active: false,
    },
    onSubmit: async (values) => {
      setLoading(true);
      setIsProcessing(true);
      setIsSuccess(false);
      setIsError(false);

      try {
        await axios.post('/api/token-pools/update', {
          token_id: editingPool.token_id,
          rate: values.rate,
          min_redeem: values.minRedeem,
          max_redeem: values.maxRedeem,
          cooldown: values.cooldown,
          is_active: values.is_active,
        });

        setLoading(false);
        setIsProcessing(false);
        setIsSuccess(true);
        setMessage('Pool updated successfully!');
        fetchPools();
        fetchTotalPools();
      } catch (error) {
        setLoading(false);
        setIsProcessing(false);
        setIsError(true);
        setMessage('Error updating pool. Please try again.');
      }
    },
  });

  const handleSymbolChange = (event, value) => {
    formik.setFieldValue('symbol', value);
    if (value) {
      setSelectedTokenDecimals(value.decimals);
      setSelectedTokenSymbol(value.symbol);
      formik.setFieldValue('symbolValue', value.symbol);
      updateRedemptionDisplay(formik.values.rate, formik.values.min_redeem, value.decimals, value.symbol);
    } else {
      setRedemptionDisplay('');
    }
  };

  const handleRateChange = (e) => {
    const value = e.target.value;
    if (/^\d*\.?\d{0,2}$/.test(value)) {
      formik.setFieldValue('rate', value);
      updateRedemptionDisplay(value, formik.values.min_redeem, selectedTokenDecimals, selectedTokenSymbol);
    } else {
      setRedemptionDisplay('');
    }
  };

  const handleMinRedeemChange = (e) => {
    const value = e.target.value;
    if (/^\d*$/.test(value)) {
      formik.setFieldValue('min_redeem', value);
      updateRedemptionDisplay(formik.values.rate, value, selectedTokenDecimals, selectedTokenSymbol);
    } else {
      setRedemptionDisplay('');
    }
  };

  const handleMaxRedeemChange = (e) => {
    const value = e.target.value;
    if (/^\d*$/.test(value)) {
      formik.setFieldValue('max_redeem', value);
    }
  };

  const handleCooldownChange = (e) => {
    const value = e.target.value;
    if (/^\d*$/.test(value)) {
      formik.setFieldValue('cooldown', value);
    }
  };

  const updateRedemptionDisplay = (rate, min_redeem, decimals, symbol) => {
    if (rate && min_redeem && decimals !== undefined && symbol) {
      let redemptionAmount = (min_redeem * (rate / 100)).toFixed(decimals);
      setRedemptionDisplay(
        `Redemption Example: User will receive ${redemptionAmount} ${symbol} per ${min_redeem} ${channelTokenName}`
      );
    } else {
      setRedemptionDisplay('');
    }
  };

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleBlurUpdate = async (id) => {
    const pool = pools.find((p) => p.token_id === id);

    const updatedRate = parseFloat(pool.rate).toFixed(4);
    const updatedMinRedeem = pool.min_redeem !== undefined ? pool.min_redeem : null;
    const updatedMaxRedeem = pool.max_redeem !== undefined ? pool.max_redeem : null;
    const updatedCooldown = pool.cooldown !== undefined ? pool.cooldown : null;
    const updatedIsActive = pool.is_active !== undefined ? pool.is_active : null;

    try {
      await axios.post('/api/token-pools/update', {
        token_id: id,
        rate: updatedRate,
        min_redeem: updatedMinRedeem,
        max_redeem: updatedMaxRedeem,
        cooldown: updatedCooldown,
        is_active: updatedIsActive,
      });
    } catch (error) {
      console.error('Error updating token pool:', error);
    } finally {
      setEditRateId(null);
      setEditMinRedeemId(null);
    }
  };

  const handleClose = () => {
    setOpen(false);
    setLoading(false);
    setIsProcessing(false);
    setIsSuccess(false);
    setIsError(false);
    setMessage('');
    setEditingPool(null);
    setRedemptionDisplay('');
    setDepositAmount('');
    setUserBalance(0);
    setSelectedPool(null);
    setWithdrawAmount('');
    setWithdrawWallet('');
    formik.resetForm(); // Reset the form when closing the modal
    fetchPools();
    fetchTotalPools();
  };

  // Handle rate change in edit modal
  const handleEditRateChange = (e) => {
    const value = e.target.value;
    // Allow only numbers with up to 2 decimal places
    if (/^\d*\.?\d{0,2}$/.test(value)) {
      formikEdit.setFieldValue('rate', value);
      // Update redemption display if needed
      updateRedemptionDisplay(value, formikEdit.values.minRedeem, selectedTokenDecimals, selectedTokenSymbol);
    }
  };

  // Handle minRedeem change in edit modal
  const handleEditMinRedeemChange = (e) => {
    const value = e.target.value;
    // Allow only whole numbers
    if (/^\d*$/.test(value)) {
      formikEdit.setFieldValue('minRedeem', value);
      // Update redemption display if needed
      updateRedemptionDisplay(formikEdit.values.rate, value, selectedTokenDecimals, selectedTokenSymbol);
    }
  };

  // Handle maxRedeem change in edit modal
  const handleEditMaxRedeemChange = (e) => {
    const value = e.target.value;
    // Allow only whole numbers
    if (/^\d*$/.test(value)) {
      formikEdit.setFieldValue('maxRedeem', value);
    }
  };

  // Handle cooldown change in edit modal
  const handleEditCooldownChange = (e) => {
    const value = e.target.value;
    // Allow only whole numbers
    if (/^\d*$/.test(value)) {
      formikEdit.setFieldValue('cooldown', value);
    }
  };

  const getModalContent = () => {
    if (modalType === 'create') {
      return (
        <form onSubmit={formik.handleSubmit} noValidate>
          <Box mb={2} />
          <Autocomplete
            options={symbols}
            getOptionLabel={(option) => option.label}
            value={formik.values.symbol}
            onChange={handleSymbolChange}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Symbol (Contract)"
                variant="outlined"
                error={formik.touched.symbol && Boolean(formik.errors.symbol)}
                helperText={formik.touched.symbol && formik.errors.symbol}
                fullWidth
                margin="normal"
              />
            )}
          />
          <TextField
            fullWidth
            id="rate"
            name="rate"
            label="Redemption Rate (%)"
            type="text"
            variant="outlined"
            value={formik.values.rate}
            onChange={handleRateChange}
            error={formik.touched.rate && Boolean(formik.errors.rate)}
            helperText={formik.touched.rate && formik.errors.rate}
            InputLabelProps={{ shrink: true }}
            margin="normal"
            InputProps={{
              inputProps: { inputMode: 'decimal', min: "0" },
            }}
          />
          <TextField
            fullWidth
            id="min_redeem"
            name="min_redeem"
            label="Minimum Redeem"
            type="text"
            variant="outlined"
            value={formik.values.min_redeem}
            onChange={handleMinRedeemChange}
            error={formik.touched.min_redeem && Boolean(formik.errors.min_redeem)}
            helperText={formik.touched.min_redeem && formik.errors.min_redeem}
            InputLabelProps={{ shrink: true }}
            margin="normal"
            InputProps={{
              inputProps: { inputMode: 'numeric' },
            }}
          />
          <TextField
            fullWidth
            id="max_redeem"
            name="max_redeem"
            label="Maximum Redeem"
            type="text"
            variant="outlined"
            value={formik.values.max_redeem}
            onChange={handleMaxRedeemChange}
            error={formik.touched.max_redeem && Boolean(formik.errors.max_redeem)}
            helperText={formik.touched.max_redeem && formik.errors.max_redeem}
            InputLabelProps={{ shrink: true }}
            margin="normal"
            InputProps={{
              inputProps: { inputMode: 'numeric' },
            }}
          />
          <TextField
            fullWidth
            id="cooldown"
            name="cooldown"
            label="Cooldown (minutes)"
            type="text"
            variant="outlined"
            value={formik.values.cooldown}
            onChange={handleCooldownChange}
            error={formik.touched.cooldown && Boolean(formik.errors.cooldown)}
            helperText={formik.touched.cooldown && formik.errors.cooldown}
            InputLabelProps={{ shrink: true }}
            margin="normal"
            InputProps={{
              inputProps: { inputMode: 'numeric' },
            }}
          />
          {redemptionDisplay && (
            <Typography variant="body2" color="#FFFFFF" sx={{ mt: 1 }}>
              {redemptionDisplay}
            </Typography>
          )}
        </form>
      );
    } else if (modalType === 'edit' && editingPool) {
      return (
        <form onSubmit={formikEdit.handleSubmit} noValidate>
          <TextField
            fullWidth
            label="Redemption Rate (%)"
            type="text"
            value={formikEdit.values.rate}
            onChange={handleEditRateChange}
            name="rate"
            margin="normal"
            InputLabelProps={{ shrink: true }}
            InputProps={{
              inputProps: { inputMode: 'decimal', min: "0" },
            }}
          />
          <TextField
            fullWidth
            label="Minimum Redeem"
            type="text"
            value={formikEdit.values.minRedeem}
            onChange={handleEditMinRedeemChange}
            name="minRedeem"
            margin="normal"
            InputLabelProps={{ shrink: true }}
            InputProps={{
              inputProps: { inputMode: 'numeric' },
            }}
          />
          <TextField
            fullWidth
            label="Maximum Redeem"
            type="text"
            value={formikEdit.values.maxRedeem}
            onChange={handleEditMaxRedeemChange}
            name="maxRedeem"
            margin="normal"
            InputLabelProps={{ shrink: true }}
            InputProps={{
              inputProps: { inputMode: 'numeric' },
            }}
          />
          <TextField
            fullWidth
            label="Cooldown (Minutes)"
            type="text"
            value={formikEdit.values.cooldown}
            onChange={handleEditCooldownChange}
            name="cooldown"
            margin="normal"
            InputLabelProps={{ shrink: true }}
            InputProps={{
              inputProps: { inputMode: 'numeric' },
            }}
          />
          {redemptionDisplay && (
            <Typography variant="body2" color="#FFFFFF" sx={{ mt: 1 }}>
              {redemptionDisplay}
            </Typography>
          )}
        </form>
      );
    } else if (modalType === 'deposit' && selectedPool) {
      return (
        <Box>
          <Typography
            variant="body2"
            color="#A0A0A0"
            sx={{ marginBottom: 2, cursor: 'pointer' }}
            onClick={handleBalanceClick}
          >
            Your Balance: {userBalance} {selectedPool.symbol}
          </Typography>
          <TextField
            label="Amount to Deposit"
            variant="outlined"
            fullWidth
            value={depositAmount}
            onChange={(e) => setDepositAmount(e.target.value)}
            type="number"
            margin="normal"
          />
        </Box>
      );
    } else if (modalType === 'withdraw') {
      return (
        <Box>
          <Typography variant="body1" onClick={handleWithdrawAmountClick} style={{ cursor: 'pointer' }}>
            Pool Balance: {parseFloat(selectedPool?.balance).toFixed(selectedPool?.decimals)} {selectedPool?.symbol}
          </Typography>
          <TextField
            label="Amount to Withdraw"
            value={withdrawAmount}
            onChange={(e) => setWithdrawAmount(e.target.value)}
            fullWidth
            margin="normal"
            required
          />
          <TextField
            label="WAX Wallet Address"
            value={withdrawWallet}
            onChange={(e) => setWithdrawWallet(e.target.value)}
            fullWidth
            margin="normal"
            required
          />
          {isError && <Typography color="error">{message}</Typography>}
        </Box>
      );
    }
    return null;
  };

  const handleOpenWithdrawModal = (pool) => {
    setSelectedPool(pool);
    setWithdrawAmount('');
    setWithdrawWallet('');
    setModalType('withdraw');
    setOpen(true);
  };

  const handleWithdrawAmountClick = () => {
    setWithdrawAmount(selectedPool.balance.toString());
  };

  const handleWithdrawSubmit = async () => {
    if (parseFloat(withdrawAmount) <= 0 || parseFloat(withdrawAmount) > selectedPool.balance) {
      setIsError(true);
      setMessage('Withdraw amount exceeds pool balance.');
      return;
    }
  
    if (!withdrawWallet) {
      setIsError(true);
      setMessage('WAX Wallet Address is required.');
      return;
    }
  
    setLoading(true);
    setIsSuccess(false);
    setIsError(false);
  
    if (!authState || !authState.twitch_id) {
      console.error('Not authenticated, please login again.');
      return;
    }
    const memo = `withdraw|${authState.twitch_id}`;
  
    try {
      const paddedWithdrawAmount = parseFloat(withdrawAmount).toFixed(selectedPool.decimals);
      const response = await axios.post('/api/transfer-token', {
        contract: selectedPool.contract,
        memo: memo,
        to: withdrawWallet,
        quantity: paddedWithdrawAmount,
        symbol: selectedPool.symbol
      }, { withCredentials: true });
  
      if (response.data.success) {
        const transactionId = response.data.result;
        setIsSuccess(true);
        setMessage(`${parseFloat(withdrawAmount).toLocaleString()} ${selectedPool.symbol} withdrawn successfully!<br/><a href="https://waxblock.io/transaction/${transactionId}" target="_blank" rel="noopener noreferrer">View the transaction</a>`);
        // Refresh token pools
        await fetchPools();
        handleClose();
      } else {
        setIsError(true);
        setMessage('Error withdrawing tokens. Please try again. ' + response.data.message);
      }
    } catch (error) {
      setIsError(true);
      setMessage('Error withdrawing tokens. Please try again.');
      console.error('Error withdrawing tokens:', error);
    } finally {
      setLoading(false);
    }
  };
  

  return (
    <Box padding={2}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '16px' }}>
        <Typography variant="h5">Token Pools</Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={handleAddPool}
          sx={{ color: '#FFFFFF', textTransform: 'none', width: 'auto', whiteSpace: 'nowrap' }}
        >
          ADD POOL
        </Button>
      </Box>
      <Typography variant="body1" color="#A0A0A0">
        Here you can manage your token pools. Toggle the active status to enable or disable a pool or use the icons to deposit or withdraw.
      </Typography>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sortDirection={orderBy === 'token_id' ? order : false}>
                <TableSortLabel
                  active={orderBy === 'token_id'}
                  direction={orderBy === 'token_id' ? order : 'asc'}
                  onClick={() => handleRequestSort('token_id')}
                >
                  Pool ID
                </TableSortLabel>
              </TableCell>
              <TableCell sortDirection={orderBy === 'symbol' ? order : false}>
                <TableSortLabel
                  active={orderBy === 'symbol'}
                  direction={orderBy === 'symbol' ? order : 'asc'}
                  onClick={() => handleRequestSort('symbol')}
                >
                  Token Symbol
                </TableSortLabel>
              </TableCell>
              <TableCell sortDirection={orderBy === 'contract' ? order : false}>
                <TableSortLabel
                  active={orderBy === 'contract'}
                  direction={orderBy === 'contract' ? order : 'asc'}
                  onClick={() => handleRequestSort('contract')}
                >
                  Contract
                </TableSortLabel>
              </TableCell>
              <TableCell sortDirection={orderBy === 'balance' ? order : false}>
                <TableSortLabel
                  active={orderBy === 'balance'}
                  direction={orderBy === 'balance' ? order : 'asc'}
                  onClick={() => handleRequestSort('balance')}
                >
                  Balance
                </TableSortLabel>
              </TableCell>
              <TableCell sortDirection={orderBy === 'rate' ? order : false}>
                <TableSortLabel
                  active={orderBy === 'rate'}
                  direction={orderBy === 'rate' ? order : 'asc'}
                  onClick={() => handleRequestSort('rate')}
                >
                  Rate
                </TableSortLabel>
              </TableCell>
              <TableCell sortDirection={orderBy === 'min_redeem' ? order : false}>
                <TableSortLabel
                  active={orderBy === 'min_redeem'}
                  direction={orderBy === 'min_redeem' ? order : 'asc'}
                  onClick={() => handleRequestSort('min_redeem')}
                >
                  Min Redeem
                </TableSortLabel>
              </TableCell>
              <TableCell sortDirection={orderBy === 'max_redeem' ? order : false}>
                <TableSortLabel
                  active={orderBy === 'max_redeem'}
                  direction={orderBy === 'max_redeem' ? order : 'asc'}
                  onClick={() => handleRequestSort('max_redeem')}
                >
                  Max Redeem
                </TableSortLabel>
              </TableCell>
              <TableCell sortDirection={orderBy === 'cooldown' ? order : false}>
                <TableSortLabel
                  active={orderBy === 'cooldown'}
                  direction={orderBy === 'cooldown' ? order : 'asc'}
                  onClick={() => handleRequestSort('cooldown')}
                >
                  Cooldown
                </TableSortLabel>
              </TableCell>
              <TableCell>
                Active
              </TableCell>
              <TableCell>
                Actions
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {pools.map((pool) => (
              <TableRow key={pool.token_id}>
                <TableCell>{pool.token_id}</TableCell>
                <TableCell>{pool.symbol}</TableCell>
                <TableCell>{pool.contract}</TableCell>
                <TableCell>{parseFloat(pool.balance).toLocaleString(undefined, { minimumFractionDigits: pool.decimals })}</TableCell>
                <TableCell>{`${parseFloat(pool.rate).toFixed(2)}%`}</TableCell>
                <TableCell>{pool.min_redeem}</TableCell>
                <TableCell>{pool.max_redeem === 0 ? '∞' : pool.max_redeem}</TableCell>
                <TableCell>{pool.cooldown}</TableCell>
                <TableCell>
                  <Switch
                    checked={pool.is_active}
                    onChange={() => handleActiveChange(pool)}
                  />
                </TableCell>
                <TableCell>
                  <Tooltip title="Edit">
                    <IconButton onClick={() => handleEditPool(pool)} sx={{ color: 'white' }}>
                      <EditIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Deposit">
                    <IconButton onClick={() => handleDeposit(pool)} sx={{ color: 'green' }}>
                      <ArrowDownwardIcon />
                    </IconButton>
                  </Tooltip>
                  {parseFloat(pool.balance) > 0 && (
                    <>
                      <Tooltip title="Withdraw">
                        <IconButton onClick={() => handleOpenWithdrawModal(pool)} sx={{ color: 'red' }}>
                          <ArrowUpwardIcon />
                        </IconButton>
                      </Tooltip>
                    </>
                  )}
                  {parseFloat(pool.balance) === 0 && (
                    <Tooltip title="Delete Pool">
                      <IconButton color="error" onClick={() => 
                        handleDeletePool(pool)}>
                        <DeleteIcon />
                      </IconButton>
                    </Tooltip>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        count={totalPools}
        page={page}
        onPageChange={(e, newPage) => setPage(newPage)}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={(e) => setRowsPerPage(parseInt(e.target.value, 10))}
        rowsPerPageOptions={[25, 50, 100]}
      />
      <Modal
        open={open}
        onClose={handleClose}
        title={
          isSuccess ? 'Success' :
          isError ? 'Error' :
          modalType === 'create' ? 'Add New Pool' :
          modalType === 'edit' ? `Editing ${selectedPool?.symbol} Pool` :
          modalType === 'deposit' ? `Depositing ${selectedPool?.symbol}` :
          modalType === 'withdraw' ? `Withdrawing ${selectedPool?.symbol}` :
          'Details'
        }
        content={getModalContent()}
        onSubmit={() => {
          if (modalType === 'create') {
            formik.handleSubmit();
          } else if (modalType === 'edit') {
            formikEdit.handleSubmit();
          } else if (modalType === 'deposit') {
            handleDepositSubmit();
          } else if (modalType === 'withdraw') {
            handleWithdrawSubmit();
          } else {
            console.error('Invalid modal type for submission:', modalType);
          }
        }}
        isProcessing={loading}
        isSuccess={isSuccess}
        isError={isError}
        message={<span style={{ color: 'white' }} dangerouslySetInnerHTML={{ __html: message }} />}
        hideSubmit={modalType === 'details' || isSuccess || isError}
      />
    </Box>
  );
};

export default TokenPool;