import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import logo from './logo.png';
import logoText from './logoText.png';
import waxLogo from './Logo_WAX_white_primary.png';
import {
  AppBar, Toolbar, IconButton, Drawer, List, ListItem, ListItemText, Box,
  useTheme, ThemeProvider, createTheme
} from '@mui/material';
import { Menu as MenuIcon } from '@mui/icons-material';
import { styled } from '@mui/system';
import {
  AuthModal, HomePage, Features, SupportForm, StreamerDashboard, ViewerDashboard,
  AuthProvider, useAuth, TokenPool, StreamerTransactionHistory, Commands, DigPool, WebSocketTest, 
  StreamerNFTTransactionHistory, DashboardLayout, MeetTheTeam, TimedMessages, Leaderboard, Settings,
  AuthCallback, LoyaltyToken, FeatureCommands, FeatureTimedMessages, EmoteOverlay, ShoutoutOverlay, 
  TimerOverlay, FollowerOverlay, DonationOverlay, SubscriptionOverlay, InteractiveChatGames, OverlayEditor,
  SessionProvider, SessionContext, AccountProvider, AccountContext, BasicPage, PremiumSubscribe, TokenPoolPublic,
  StreamerPage
}  from './components';
import { ChatOverlay } from './overlays'
import './globalStyles.css';

const Root = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  minHeight: '100vh',
});

const AppBarStyled = styled(AppBar)({
  backgroundColor: '#060d1f',
  borderBottom: '3px solid #ff7e04',
  width: '100vw',
  left: 0,
  right: 0,
  position: 'fixed',
  zIndex: 1000,
});

const ToolbarStyled = styled(Toolbar)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  position: 'relative',
  height: 85,
}));

const Content = styled('div')(({ theme, isHomePage }) => ({
  backgroundColor: isHomePage ? 'transparent' : '#32384B',
  color: '#FFFFFF',
  flex: 1,
  boxSizing: 'border-box',
  overflowX: 'auto',
  padding: 0,
}));

const LogoContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  [theme.breakpoints.up('sm')]: {
    justifyContent: 'flex-start',
  },
  [theme.breakpoints.down('sm')]: {
    justifyContent: 'center',
  },
}));

const MenuIconStyled = styled(IconButton)(({ theme }) => ({
  display: 'none',
  color: '#FFFFFF',
  paddingRight: '16px',
  [theme.breakpoints.down('sm')]: {
    display: 'block',
    paddingRight: '16px',
  },
}));

const Logo = styled('img')(({ theme }) => ({
  height: 55,
  padding: 10,
  cursor: 'pointer',
}));

const LogoText = styled('img')(({ theme }) => ({
  height: 50,
  padding: 5,
  cursor: 'pointer'
}));

const DrawerStyled = styled(Drawer)({
  '& .MuiPaper-root': {
    backgroundColor: '#060d1f',
    color: '#FFFFFF',
  },
});

const ListItemStyled = styled(ListItem)(({ selected }) => ({
  '& .MuiListItemText-root': {
    fontWeight: selected ? 'bold' : 'normal',
    color: selected ? '#ff7e04' : '#FFFFFF',
  },
  '&:hover': {
    backgroundColor: '#333333',
    '& .MuiListItemText-root': {
      fontWeight: 'bold',
      transition: 'font-weight 0.3s ease',
    },
  },
  paddingLeft: '16px'
}));

const GridContainer = styled('div')({
  display: 'flex',
  flex: 1,
  marginTop: '88px',
  marginBottom: '40px',
});

const Footer = styled('footer')({
  backgroundColor: '#060d1f',
  color: '#FFFFFF',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderTop: '1px solid #ff7e04',
  width: '100%',
  marginTop: 'auto',
  zIndex: 1000,
  position: 'fixed',
  bottom: 0,
});

const NavText = styled('span')(({ selected }) => ({
  color: selected ? '#ff7e04' : '#FFFFFF',
  marginLeft: 20,
  cursor: 'pointer',
}));

const menuItems = [
  { label: 'Features', link: '/features' },
  { label: 'Dashboard', link: '/dashboard' },
  { label: 'Meet The Team', link: '/meet-the-team' },
  { label: 'Support', link: '/support' },
];

function ProtectedRoute({ children, role }) {
  const { authState, loading } = useAuth(); // Destructure loading state
  const [authModalOpen, setAuthModalOpen] = useState(false);

  useEffect(() => {
    if (!loading && !authState.isAuthenticated) {
      setAuthModalOpen(true);
    }
  }, [loading, authState.isAuthenticated]);

  if (loading) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: 'calc(100vh - 85px - 40px)', marginTop: '85px', marginBottom: '40px' }}>
        <div className="spinner"></div>
      </div>
    ); // Show loading spinner while checking auth state
  }

  if (!authState.isAuthenticated) {
    return (
      <>
        <AuthModal open={authModalOpen} onClose={() => setAuthModalOpen(false)} />
        <div>Please login to access this page.</div>
      </>
    );
  }

  if (role && authState.role !== role && !(role === 'viewer' && authState.role === 'streamer')) {
    return <div>Access Denied. Please login as a {role}.</div>;
  }

  return React.cloneElement(children, { authState });
}

function AppContent() {
  const theme = useTheme();
  const { authState } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [authModalOpen, setAuthModalOpen] = useState(false);

  const isOverlayRoute = location.pathname.startsWith('/overlays/');
  const isHomePage = location.pathname === '/';

  const background = isOverlayRoute || isHomePage
    ? theme.palette.background.overlay   // Use transparent background for overlays and home page
    : theme.palette.background.default;  // Default background for other routes

  const handleMenuItemClick = (link) => {
    if (link === '/dashboard') {
      if (authState.isAuthenticated) {
        if (authState.role === 'streamer') {
          navigate('/streamer-dashboard');
        } else if (authState.role === 'viewer') {
          navigate('/viewer-dashboard');
        }
      } else {
        setAuthModalOpen(true);
      }
    } else {
      navigate(link);
    }
    setDrawerOpen(false);
  };

  const handleLogoClick = () => {
    navigate('/');
  };

  const toggleDrawer = (open) => () => {
    setDrawerOpen(open);
  };

  return (
    <div style={{ backgroundColor: background }}>
      {!isOverlayRoute ? (
        <Root>
          <AppBarStyled position="static">
            <ToolbarStyled>
              <LogoContainer>
                <Logo src={logo} alt="Scurvy Bot Logo" onClick={handleLogoClick} />
                <LogoText src={logoText} alt="Logo Text" onClick={handleLogoClick} />
              </LogoContainer>

              <MenuIconStyled
                edge="start"
                color="inherit"
                aria-label="menu"
                onClick={toggleDrawer(true)}
              >
                <MenuIcon />
              </MenuIconStyled>

              <DrawerStyled anchor="right" open={drawerOpen} onClose={toggleDrawer(false)}>
                <List>
                  {menuItems.map((item) => (
                    <ListItemStyled
                      button
                      key={item.label}
                      onClick={() => handleMenuItemClick(item.link)}
                      selected={location.pathname === item.link}
                    >
                      <ListItemText primary={item.label} />
                    </ListItemStyled>
                  ))}
                </List>
              </DrawerStyled>

              <Box sx={{ display: { xs: 'none', sm: 'flex' }, alignItems: 'center' }}>
                <Box>
                  {menuItems.map((item) => (
                    <NavText
                      key={item.label}
                      onClick={() => handleMenuItemClick(item.link)}
                      sx={{
                        cursor: 'pointer',
                        '&:hover': {
                          color: 'primary.main',
                        },
                      }}
                      selected={location.pathname === item.link}
                    >
                      {item.label}
                    </NavText>
                  ))}
                </Box>
              </Box>
            </ToolbarStyled>
          </AppBarStyled>

          <GridContainer>
            <Content isHomePage={isHomePage}>
              <Routes>
                <Route path="/" element={<HomePage />} />
                <Route path="/features" element={<Features />} />
                <Route path="/support" element={<SupportForm />} />
                <Route path="/meet-the-team" element={<MeetTheTeam />} />
                <Route path="/features/loyalty-token" element={<LoyaltyToken />} />
                <Route path="/features/commands" element={<FeatureCommands />} />
                <Route path="/features/timed-messages" element={<FeatureTimedMessages />} />
                <Route path="/features/emotes-overlays" element={<EmoteOverlay />} />
                <Route path="/features/shoutout-overlays" element={<ShoutoutOverlay />} />
                <Route path="/features/timer-overlay" element={<TimerOverlay />} />
                <Route path="/features/follower-goal-overlays" element={<FollowerOverlay />} />
                <Route path="/features/donation-goal-overlays" element={<DonationOverlay />} />
                <Route path="/features/subscription-goal-overlays" element={<SubscriptionOverlay />} />
                <Route path="/features/interactive-chat-games" element={<InteractiveChatGames />} />
                <Route path="/websocket-test" element={<WebSocketTest />} />
                <Route path="/overlays/overlay-editor" element={<OverlayEditor />} />
                <Route path="/basic-page" element={<BasicPage />} />
                <Route path="/token-pools/:channelName" element={<TokenPoolPublic />} />
                {/*
                
                <Route path="/features/end-credits-overlay" element={<EndCreditsOverlay />} />
                <Route path="/features/global-promo-minigames" element={<GlobalPromoMinigames />} />
                <Route path="/features/wallet-creation" element={<WalletCreation />} />
                <Route path="/features/token-creation" element={<TokenCreation />} />
                <Route path="/features/loyalty-token-redemption" element={<LoyaltyTokenRedemption />} />
                <Route path="/features/treasure-digs" element={<TreasureDigs />} />
                <Route path="/features/scurvy-store" element={<ScurvyStore />} />
                <Route path="/features/token-airdrops" element={<TokenAirdrops />} />
                <Route path="/features/giveaway-overlays" element={<GiveawayOverlays />} /> */}
                <Route
                  path="/dashboard"
                  element={
                    <ProtectedRoute role="">
                      {authState.role === 'streamer' ? (
                        <DashboardLayout>
                          <StreamerDashboard />
                        </DashboardLayout>
                      ) : authState.role === 'viewer' ? (
                        <DashboardLayout>
                          <ViewerDashboard />
                        </DashboardLayout>
                      ) : (
                        <div>Please log in to access the dashboard</div>
                      )}
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/streamer-dashboard"
                  element={
                    <ProtectedRoute role="streamer">
                      <DashboardLayout>
                        <StreamerDashboard />
                      </DashboardLayout>
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/streamer-dashboard/token-pools"
                  element={
                    <ProtectedRoute role="streamer">
                      <DashboardLayout>
                        <TokenPool />
                      </DashboardLayout>
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/streamer-dashboard/token-transactions"
                  element={
                    <ProtectedRoute role="streamer">
                      <DashboardLayout>
                        <StreamerTransactionHistory />
                      </DashboardLayout>
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/streamer-dashboard/nft-pool"
                  element={
                    <ProtectedRoute role="streamer">
                      <DashboardLayout>
                        <DigPool authState={authState} />
                      </DashboardLayout>
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/streamer-dashboard/subscribe"
                  element={
                    <ProtectedRoute role="streamer">
                      <DashboardLayout>
                        <PremiumSubscribe authState={authState} />
                      </DashboardLayout>
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/streamer-dashboard/nft-transactions"
                  element={
                    <ProtectedRoute role="streamer">
                      <DashboardLayout>
                        <StreamerNFTTransactionHistory />
                      </DashboardLayout>
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/streamer-dashboard/commands"
                  element={
                    <ProtectedRoute role="streamer">
                      <DashboardLayout>
                        <Commands />
                      </DashboardLayout>
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/streamer-dashboard/timed-messages"
                  element={
                    <ProtectedRoute role="streamer">
                      <DashboardLayout>
                        <TimedMessages />
                      </DashboardLayout>
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/streamer-dashboard/leaderboard"
                  element={
                    <ProtectedRoute role="streamer">
                      <DashboardLayout>
                        <Leaderboard />
                      </DashboardLayout>
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/streamer-dashboard/settings"
                  element={
                    <ProtectedRoute role="streamer">
                      <DashboardLayout>
                        <Settings />
                      </DashboardLayout>
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/viewer-dashboard"
                  element={
                    <ProtectedRoute role="viewer">
                      <DashboardLayout>
                        <ViewerDashboard />
                      </DashboardLayout>
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/viewer-dashboard/balances"
                  element={
                    <ProtectedRoute role="viewer">
                      <DashboardLayout>
                        <div>Balances</div>
                      </DashboardLayout>
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/viewer-dashboard/transactions"
                  element={
                    <ProtectedRoute role="viewer">
                      <DashboardLayout>
                        <div>Transactions</div>
                      </DashboardLayout>
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/viewer-dashboard/streamer/:channelName"
                  element={
                    <ProtectedRoute role="viewer">
                      <DashboardLayout>
                        <StreamerPage />
                      </DashboardLayout>
                    </ProtectedRoute>
                  }
                />
                <Route path="/auth-callback" element={<AuthCallback />} />
              </Routes>
            </Content>
          </GridContainer>

          <Footer>
            Partnered with
            <a href="https://www.wax.io" target="_blank" rel="noopener noreferrer">
              <img src={waxLogo} alt="Wax Logo" style={{ height: '35px' }} />
            </a>
            - © 2023 ScurvyBot
          </Footer>
        </Root>
      ) : (
        <Routes>
          {/* Only render the overlays without any layout */}
          <Route path="/overlays/overlay-editor" element={<OverlayEditor />} />
          <Route path="/overlays/chat-overlay" element={<ChatOverlay />} />
        </Routes>
      )}
    </div>
  );
}

function App() {
  const theme = createTheme({
    palette: {
      primary: {
        main: '#ff7e04',
      },
      secondary: {
        main: '#FFFFFF',
      },
      background: {
        default: '#32384B',
        paper: '#060d1f',
        overlay: '#32384B',
      },
      text: {
        primary: '#FFFFFF',
      },
      action: {
        hover: '#2d3143',
      },
      error: {
        main: '#870b04',
      },
    },
    components: {
      MuiTableContainer: {
        styleOverrides: {
          root: {
            backgroundColor: '#32384B',
            color: '#FFFFFF',
            border: '1.5px solid #FFFFFF',
            borderRadius: '8px',
            overflowX: 'auto',
            display: 'block',
          },
        },
      },
      MuiTableCell: {
        styleOverrides: {
          root: {
            border: '1px solid #D3D3D3',
            color: '#FFFFFF',
            whiteSpace: 'nowrap',
            padding: '8px',
          },
          head: {
            backgroundColor: '#060d1f',
            color: '#FFFFFF',
            fontWeight: 'bold',
            border: '1.5px solid #FFFFFF',
            borderBottom: 'none',
            whiteSpace: 'nowrap',
          },
        },
      },
      MuiTableRow: {
        styleOverrides: {
          root: {
            '&:nth-of-type(odd)': {
              backgroundColor: '#32384B',
            },
            '&:nth-of-type(even)': {
              backgroundColor: '#2d3143',
            },
          },
        },
      },
      MuiTextField: {
        defaultProps: {
          variant: 'outlined',
          InputLabelProps: {
            shrink: true,
          },
        },
        styleOverrides: {
          root: {
            '& .MuiInputBase-root': {
              padding: '8px',
              color: '#FFFFFF',
            },
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                borderColor: '#FFFFFF',
              },
              '&:hover fieldset': {
                borderColor: '#FFFFFF',
              },
              '&.Mui-focused fieldset': {
                borderColor: '#ff7e04',
              },
              '&.Mui-error .MuiOutlinedInput-notchedOutline': {
                borderColor: '#870b04',
              },
              '&.Mui-disabled .MuiOutlinedInput-notchedOutline': {
                borderColor: '#BDBDBD', // Grey border color for disabled input
              },
            },
            '& .MuiInputLabel-root': {
              color: '#A0A0A0',
              fontSize: '1rem',
            },
            '& .MuiInputLabel-root.Mui-focused': {
              color: '#ff7e04',
            },
          },
        },
      },
      MuiSelect: {
        styleOverrides: {
          root: {
            color: '#FFFFFF',
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                borderColor: '#FFFFFF',
              },
              '&:hover fieldset': {
                borderColor: '#FFFFFF',
              },
              '&.Mui-focused fieldset': {
                borderColor: '#ff7e04',
              },
              '&.Mui-disabled .MuiOutlinedInput-notchedOutline': {
                borderColor: '#BDBDBD', // Grey border color for disabled input
              },
            },
          },
        },
        defaultProps: {
          inputProps: {
            shrink: true,
          },
        },
      },
      MuiInputAdornment: {
        styleOverrides: {
          root: {
            color: '#FFFFFF', // Default color for adornment
          },
        },
      },   
      MuiInputBase: {
        styleOverrides: {
          root: {
            color: '#FFFFFF', // Default input text color
          },
        },
      },   
      MuiDrawer: {
        styleOverrides: {
          paper: {
            backgroundColor: '#060d1f',
            color: '#FFFFFF',
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            backgroundColor: '#ff7e04',
            color: '#FFFFFF',
            '&:hover': {
              backgroundColor: '#cc6503',
            },
            height: '40px',
            width: '100px',
            margin: '8px',
          },
        },
        variants: [
          {
            props: { variant: 'fullWidth' },
            style: {
              width: '100%',
              backgroundColor: '#ff7e04',
              color: '#FFFFFF',
              '&:hover': {
                backgroundColor: '#cc6503',
              },
            },
          },
          {
            props: { variant: '120px' },
            style: {
              width: '120px',
              backgroundColor: '#ff7e04',
              color: '#FFFFFF',
              '&:hover': {
                backgroundColor: '#cc6503',
              },
            },
          },
          {
            props: { variant: 'contained' },
            style: {
              backgroundColor: '#ff7e04',
              color: '#FFFFFF',
              '&:hover': {
                backgroundColor: '#cc6503',
              },
            },
          },
          {
            props: { variant: 'error' },
            style: {
              backgroundColor: '#870b04',
              color: '#FFFFFF',
              '&:hover': {
                backgroundColor: '#5d0703',
              },
            },
          },
        ],
      },    
      MuiMenu: {
        styleOverrides: {
          paper: {
            backgroundColor: '#060d1f',
          },
        },
      },
      MuiMenuItem: {
        styleOverrides: {
          root: {
            color: '#FFFFFF',
            backgroundColor: '#060d1f',
            '&:hover': {
              backgroundColor: '#32384B',
            },
            '&.Mui-selected': {
              backgroundColor: '#32384B !important',
              color: '#FFFFFF',
              '&:hover': {
                backgroundColor: '#2d3143',
              },
            },
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            '&:hover .MuiOutlinedInput-notchedOutline': {
              borderColor: '#FFFFFF',
            },
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
              borderColor: '#ff7e04',
            },
            '&.Mui-error .MuiOutlinedInput-notchedOutline': {
              borderColor: '#FFFFFF',
            },
          },
          notchedOutline: {
            borderColor: '#FFFFFF',
          },
        },
      },
      MuiInputLabel: {
        styleOverrides: {
          root: {
            color: '#A0A0A0',
            '&.Mui-focused': {
              color: '#ff7e04',
            },
            '&.MuiFormLabel-filled': {
              transform: 'translate(14px, -6px) scale(0.75)',
            },
            '&.Mui-error': {
              color: '#FFFFFF',
            },
          },
          shrink: {
            transform: 'translate(14px, -6px) scale(0.75)',
          },
        },
      },
      MuiFormHelperText: {
        styleOverrides: {
          root: {
            '&.Mui-error': {
              color: '#FFFFFF',
            },
          },
        },
      },
      MuiSwitch: {
        styleOverrides: {
          root: {
            color: '#FFFFFF',
          },
          switchBase: {
            '&.Mui-checked': {
              color: '#ff7e04',
              '&:hover': {
                backgroundColor: 'rgba(255, 126, 4, 0.08)',
              },
            },
            '&.Mui-checked + .MuiSwitch-track': {
              backgroundColor: '#ff7e04',
            },
          },
        },
      },
      MuiCheckbox: {
        styleOverrides: {
          root: {
            color: '#FFFFFF',
            '&.Mui-checked': {
              color: '#ff7e04',
            },
            '& .MuiSvgIcon-root': {
              color: 'inherit',
            },
            '&.MuiCheckbox-indeterminate': {
              color: '#ff7e04',
            },
          },
        },
      },
      MuiTableSortLabel: {
        styleOverrides: {
          root: {
            color: '#FFFFFF',
            fontWeight: 'bold',
            '&:hover': {
              color: '#FFFFFF',
            },
            '&.Mui-active': {
              color: '#FFFFFF',
            },
            '& .MuiTableSortLabel-icon': {
              color: '#FFFFFF !important',
            },
          },
        },
      },
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <Router>
        <AuthProvider>
          <SessionProvider>
            <AccountProvider>
              <AppContent />
            </AccountProvider>
          </SessionProvider>
        </AuthProvider>
      </Router>
    </ThemeProvider>
  );
}

export default App;
