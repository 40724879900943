import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Typography, Grid, Avatar, Paper, List, ListItem, ListItemIcon, IconButton } from '@mui/material';
import axios from 'axios';
import { Refresh } from '@mui/icons-material';
import { formatDistanceToNow } from 'date-fns';

const StreamerPage = () => {
  const { channelName } = useParams();
  const [userInfo, setUserInfo] = useState(null);
  const [balance, setBalance] = useState(null);
  const [activity, setActivity] = useState([]);

  useEffect(() => {
    const fetchStreamerData = async () => {
      try {
        const userResponse = await axios.get(`/api/twitch/user/${channelName}`, { withCredentials: true });
        setUserInfo(userResponse.data);

        const balanceResponse = await axios.get(`/api/viewer/balance/${channelName}`, { withCredentials: true });
        setBalance(balanceResponse.data.data[0]);

        const activityResponse = await axios.get(`/api/viewer/activity/${channelName}`, { withCredentials: true });
        setActivity(activityResponse.data.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchStreamerData();

    const intervalId = setInterval(fetchStreamerData, 120000); // Refresh every 2 minutes

    return () => clearInterval(intervalId);
  }, [channelName]);

  useEffect(() => {
    const script = document.createElement('script');
    script.src = "https://embed.twitch.tv/embed/v1.js";
    script.async = true;
    script.onload = () => {
      if (window.Twitch && window.Twitch.Embed) {
        const options = {
          width: "100%",
          height: "100%",
          channel: channelName,
          layout: "video-with-chat",
          parent: ["scurvy.dasker.co.uk"]
        };
        new window.Twitch.Embed("twitch-embed", options);
      }
    };
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, [channelName]);

  const handleRefreshBalance = async () => {
    try {
      const balanceResponse = await axios.get(`/api/viewer/balance/${channelName}`, { withCredentials: true });
      setBalance(balanceResponse.data.data[0]);

      const activityResponse = await axios.get(`/api/viewer/activity/${channelName}`, { withCredentials: true });
      setActivity(activityResponse.data.data);
    } catch (error) {
      console.error('Error refreshing balance:', error);
    }
  };

  const formatDate = (date) => formatDistanceToNow(new Date(date), { addSuffix: true });

  const getIcon = (actionType) => {
    switch (actionType) {
      case 'dig':
        return <DigIcon />;
      case 'prize':
        return <GiftIcon />;
      default:
        return null;
    }
  };

  const getAssetText = (count) => (count === 1 ? 'asset' : 'assets');

  const DigIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" fill="currentColor" style={{ marginRight: '4px', color: '#ff7e04' }}>
      <path fill="currentColor" d="m15.1 1.81l-2.83 2.83c-.77.78-.77 2.05 0 2.83l1.41 1.41l-4.55 4.55l-2.82-2.83L4.89 12c-4.95 5-1.39 8.5-1.39 8.5S7 24 12 19.09l1.41-1.41l-2.8-2.8l4.54-4.54l1.39 1.39c.78.77 2.05.77 2.83 0L22.2 8.9zm2.83 8.47L16.55 8.9l-1.44-1.44l-1.4-1.4l1.41-1.41l4.23 4.23z"/>
    </svg>
  );

  const GiftIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" fill="currentColor" style={{ marginRight: '4px', color: '#ff7e04' }}>
      <path d="M11 14v8H7a3 3 0 0 1-3-3v-4a1 1 0 0 1 1-1zm8 0a1 1 0 0 1 1 1v4a3 3 0 0 1-3 3h-4v-8zM16.5 2a3.5 3.5 0 0 1 3.163 5H20a2 2 0 0 1 2 2v1a2 2 0 0 1-2 2h-7V7h-2v5H4a2 2 0 0 1-2-2V9a2 2 0 0 1 2-2h.337A3.5 3.5 0 0 1 4 5.5C4 3.567 5.567 2 7.483 2c1.755-.03 3.312 1.092 4.381 2.934l.136.243c1.033-1.914 2.56-3.114 4.291-3.175zm-9 2a1.5 1.5 0 0 0 0 3h3.143C9.902 5.095 8.694 3.98 7.5 4m8.983 0c-1.18-.02-2.385 1.096-3.126 3H16.5a1.5 1.5 0 1 0-.017-3"/>
    </svg>
  );

  const renderBalanceRow = (balance) => (
    <ListItem
      sx={{ p: 1, backgroundColor: '#fff', marginBottom: '6px', borderRadius: 1, color: '#000' }}
    >
      <ListItemIcon sx={{ minWidth: 'auto', marginRight: '12px' }}>
        <Avatar src={balance.profile_image_url} sx={{ width: 40, height: 40 }} />
      </ListItemIcon>
      <Typography>
        <Box component="span" sx={{ fontWeight: 'bold' }}>
          {balance.display_name}
        </Box>{' '}
        <Box component="span" sx={{ color: 'text.secondary', marginLeft: 0.5 }}>
          {Number(parseFloat(balance.balance)).toLocaleString()} {balance.token_name}
        </Box>
      </Typography>
    </ListItem>
  );

  const renderActivityRow = (activity, index) => (
    <ListItem
      key={index}
      sx={{ p: 1, backgroundColor: '#fff', marginBottom: '6px', borderRadius: 1, color: '#000' }}
    >
      <ListItemIcon sx={{ minWidth: 'auto', marginRight: '4px' }}>{getIcon(activity.action_type)}</ListItemIcon>
      <Typography>
        <Box component="span" sx={{ fontWeight: 'bold' }}>
          {activity.action_type === 'dig' ? 'Dug up' : activity.action_type === 'prize' ? 'Won' : 'Dug up'} {Number(activity.action_count).toLocaleString()} {getAssetText(activity.action_count)}
        </Box>{' '}
        <Box component="span" sx={{ color: 'text.secondary', marginLeft: 0.5 }}>
          {formatDate(activity.action_date)}
        </Box>
      </Typography>
    </ListItem>
  );

  return (
    <Box sx={{ padding: 1 }}>
      <Grid container alignItems="center" spacing={2} sx={{ marginBottom: 2 }}>
        <Grid item>
          {userInfo && <Avatar src={userInfo.profile_image_url} sx={{ width: 80, height: 80 }} />}
        </Grid>
        <Grid item>
          <Typography variant="h4" sx={{ fontWeight: 'bold' }}>
            {channelName}
          </Typography>
        </Grid>
      </Grid>
      <Grid container spacing={0} sx={{ height: { xs: 'calc(100vh - 128px - 130px)', md: 'calc(100vh - 128px - 130px)' } }}>
        <Grid item xs={12} md={8} sx={{ height: { xs: 'calc(50vh - 64px)', md: '100%' }, minWidth: 340, minHeight: 400 }}>
          <Box id="twitch-embed" sx={{ width: '100%', height: '100%', position: 'relative', zIndex: 1 }}></Box>
        </Grid>
        <Grid item xs={12} md={4} sx={{ height: { xs: 'calc(50vh - 64px)', md: '100%' }, paddingLeft: { md: 2, xs: 0 } }}>
          <Paper sx={{ height: '100%', overflowY: 'auto', backgroundColor: '#424867', padding: 0, position: 'relative', zIndex: 0 }}>
            <Box sx={{ 
              display: 'flex', 
              justifyContent: 'space-between', 
              alignItems: 'center', 
              padding: '16px', 
              marginBottom: 2 
            }}>
              <Typography variant="h5">Scurvy Actions</Typography>
              <IconButton onClick={handleRefreshBalance} sx={{ color: '#fff' }}>
                <Refresh />
              </IconButton>
            </Box>
            <List sx={{ padding: '0 16px 16px 16px' }}>
              {balance && renderBalanceRow(balance)}
              {activity.map((activity, index) => renderActivityRow(activity, index))}
              {/* Add other scurvy actions here */}
            </List>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default StreamerPage;
