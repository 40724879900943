import React, { useEffect, useState, useContext } from 'react';
import axios from 'axios';
import { Box, Paper, Typography, Grid, Button, Tooltip } from '@mui/material';
import { useAuth } from './AuthContext';
import { SessionContext } from './SessionContext';
import { AccountContext } from './AccountContext';
import { Serializer } from "@wharfkit/antelope";
import Modal from './Modal'; // Import the Modal component
import DiscountIcon from '@mui/icons-material/LocalOffer'; // Import discount icon

const PremiumSubscribe = () => {
  const [subscriptionItems, setSubscriptionItems] = useState([]);
  const [waxPrice, setWaxPrice] = useState(null);
  const { session, loginWallet } = useContext(SessionContext);
  const { account } = useContext(AccountContext);
  const { authState } = useAuth();
  const [loading, setLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);
  const [message, setMessage] = useState('');
  const [open, setOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [currentExpiry, setCurrentExpiry] = useState(null);
  const [nextExpiry, setNextExpiry] = useState(null);

  useEffect(() => {
    const fetchSubscriptionItems = async () => {
      try {
        const response = await axios.get('/api/subscription-items', { withCredentials: true });
        setSubscriptionItems(response.data);
      } catch (error) {
        console.error('Error fetching subscription items:', error);
      }
    };

    const fetchWaxPrice = async () => {
      try {
        const response = await axios.get('/api/wax-price', { withCredentials: true });
        setWaxPrice(1 / response.data.usd_price); // Convert USD to WAX
      } catch (error) {
        console.error('Error fetching WAX price:', error);
      }
    };

    const fetchUserPremiumExpiry = async () => {
      try {
        const response = await axios.get('/api/premium-expiry', { withCredentials: true });
        if (response.data && response.data.success && response.data.data.length > 0 && response.data.data[0].premium_expiry) {
          const expiryDate = new Date(response.data.data[0].premium_expiry);
          setCurrentExpiry(expiryDate);
        } else {
          console.error('Invalid response data:', response.data);
        }
      } catch (error) {
        console.error('Error fetching user premium expiry:', error);
      }
    };

    fetchSubscriptionItems();
    fetchWaxPrice();
    fetchUserPremiumExpiry(); // Fetch the current expiry date on page load
  }, []);

  const calculateNextExpiry = (currentExpiry, item) => {
    const newExpiry = new Date(currentExpiry);
    switch (item.date_type) {
      case 'day':
        newExpiry.setDate(newExpiry.getDate() + item.date_units);
        break;
      case 'week':
        newExpiry.setDate(newExpiry.getDate() + item.date_units * 7);
        break;
      case 'month':
        newExpiry.setMonth(newExpiry.getMonth() + item.date_units);
        break;
      case 'year':
        newExpiry.setFullYear(newExpiry.getFullYear() + item.date_units);
        break;
      default:
        break;
    }
    return newExpiry;
  };

  const formatDate = (date) => {
    return date.toLocaleDateString(undefined, {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    });
  };

  const formatPrice = (price) => `$${Number(price).toFixed(2)}`;

  const calculateSavings = (price, discount) => {
    return ((discount / 100) * price).toFixed(2);
  };

  const handleBuyNow = async (item) => {
    if (!session) {
      await loginWallet();
      if (!session) {
        return;
      }
    }

    setSelectedItem(item); // Store the selected item
    setNextExpiry(calculateNextExpiry(currentExpiry || new Date(), item)); // Calculate the next expiry date
    setOpen(true); // Open the confirmation modal
  };

  const handleConfirmPurchase = async () => {
    setLoading(true);
    setIsSuccess(false);
    setIsError(false);

    if (!authState || !authState.twitch_id) {
      console.error('Not authenticated, please login again.');
      return;
    }

    const memo = `sub|${authState.twitch_id}|${selectedItem.item_id}`;

    try {
      if (selectedItem.category === 'trial') {
        // Handle trial
        await axios.post('/api/subscription-trial', { item_id: selectedItem.item_id }, { withCredentials: true });
        setIsSuccess(true);
        setMessage('Trial activated successfully!');
      } else {
        // Handle WAX transaction
        if (!waxPrice || selectedItem.price <= 0) {
          throw new Error('Invalid WAX price or item price.');
        }

        const discountAmount = calculateSavings(selectedItem.price, selectedItem.discount);
        const waxAmount = ((Number(selectedItem.price) - discountAmount) * waxPrice).toFixed(8); // Convert price to WAX amount

        const action = {
          account: 'eosio.token',
          name: 'transfer',
          authorization: [session.permissionLevel],
          data: {
            from: session.actor.toString(),
            to: 'subscribe.sb',
            quantity: `${waxAmount} WAX`,
            memo: memo,
          },
        };

        const result = await session.transact({ action });
        const serialisedTransaction = Serializer.objectify(result);

        if (result) {
          const transactionId = serialisedTransaction.response?.processed?.id;
          setIsSuccess(true);
          setMessage(`Subscription purchased successfully!<br/><a href="https://waxblock.io/transaction/${transactionId}" target="_blank" rel="noopener noreferrer">View the transaction</a>`);
        }
      }
    } catch (error) {
      console.error('Transaction failed', error);
      setIsError(true);
      setMessage('Error processing transaction. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleClose = () => {
    setOpen(false);
    setLoading(false);
    setIsSuccess(false);
    setIsError(false);
    setMessage('');
    setSelectedItem(null);
    setNextExpiry(null);
  };

  return (
    <Box sx={{ padding: 2, overflow: 'hidden', display: 'flex', flexDirection: 'column' }}>
      <Typography variant="h5" color="#FFFFFF">
        Subscribe to Premium
      </Typography>
      <Typography variant="body1" color="#A0A0A0">
        Choose your subscription plan and enjoy premium features.
      </Typography>
      <Grid container spacing={2}>
        {subscriptionItems.map((item) => (
          <Grid item xs={12} sm={4} key={item.item_id}>
            <Paper sx={{ padding: 2, backgroundColor: '#424867', color: '#fff', borderRadius: 1, position: 'relative' }}>
              {item.discount > 0 && (
                <Box sx={{ position: 'absolute', top: 8, right: 8, color: '#ff7e04', display: 'flex', alignItems: 'center' }}>
                  <DiscountIcon />
                  <Typography variant="body2" sx={{ marginLeft: 0.5 }}>
                    -{parseFloat(item.discount).toFixed(2)}%
                  </Typography>
                </Box>
              )}
              <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                {item.name}
              </Typography>
              <Typography variant="body1" sx={{ marginBottom: 1 }}>
                {item.description}
              </Typography>
              <Typography variant="h5" sx={{ fontWeight: 'bold', color: '#ff7e04', display: 'inline' }}>
                {formatPrice(item.price - calculateSavings(item.price, item.discount))}
              </Typography>
              {item.discount > 0 && (
                <Typography variant="body2" sx={{ textDecoration: 'line-through', color: 'gray', display: 'inline', marginLeft: 1 }}>
                  {formatPrice(item.price)}
                </Typography>
              )}
              <Button
                variant="120px"
                color="primary"
                onClick={() => handleBuyNow(item)}
                sx={{ marginTop: 2 }}
                disabled={loading}
              >
                Buy Now
              </Button>
            </Paper>
          </Grid>
        ))}
      </Grid>
      <Modal
        open={open}
        onClose={handleClose}
        title={isSuccess ? "Purchase Successful" : "Confirm Purchase"}
        content={
          isSuccess ? (
            <Typography variant="body1" color="#FFFFFF" dangerouslySetInnerHTML={{ __html: message }} />
          ) : (
            <Box>
              <Typography variant="body1" color="#FFFFFF">
                You are about to purchase <strong>{selectedItem?.name}</strong> for <strong>{formatPrice(selectedItem?.price - calculateSavings(selectedItem?.price, selectedItem?.discount))}</strong>.
              </Typography>
              <Typography variant="body1" color="#FFFFFF" sx={{ marginTop: 2 }}>
                Current Expiry: {currentExpiry ? formatDate(currentExpiry) : 'N/A'}
              </Typography>
              <Typography variant="body1" color="#FFFFFF">
                Next Expiry: {nextExpiry ? formatDate(nextExpiry) : 'N/A'}
              </Typography>
              <Typography variant="body2" color="#FF0000" sx={{ marginTop: 2 }}>
                All purchases are non-refundable.
              </Typography>
            </Box>
          )
        }
        onSubmit={handleConfirmPurchase}
        isProcessing={loading}
        isSuccess={isSuccess}
        isError={isError}
        message={<span style={{ color: 'white' }} dangerouslySetInnerHTML={{ __html: message }} />}
        hideSubmit={isSuccess || isError}
        submitButtonText="Confirm & Pay"
        submitButtonProps={{ variant: 'contained' }}
      />
    </Box>
  );
};

export default PremiumSubscribe;
