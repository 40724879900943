import React, { useState, useEffect, useContext } from 'react';
import {
  Table, TableBody, TableHead, TableContainer, Paper, Typography, Box, TableSortLabel, TableCell, TableRow, TablePagination, Avatar, Switch
} from '@mui/material';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { SessionContext } from './SessionContext';
import { AccountContext } from './AccountContext';

const TokenPoolPublic = () => {
  const { channelName } = useParams();
  const { session } = useContext(SessionContext);
  const { account } = useContext(AccountContext);
  const [pools, setPools] = useState([]);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('token_id');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [totalPools, setTotalPools] = useState(0);
  const [userInfo, setUserInfo] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchUserInfo();
    fetchTotalPools();
    fetchPools();
  }, [orderBy, order, page, rowsPerPage]);

  const fetchUserInfo = async () => {
    try {
      const response = await axios.get(`/api/twitch/user/${channelName}`);
      setUserInfo(response.data);
    } catch (error) {
      console.error('Error fetching user info:', error);
      setError('User not found');
    }
  };

  const fetchPools = async () => {
    try {
      const response = await axios.get(`/api/channel/${channelName}/token-pools`, {
        params: { sort_by: orderBy, sort_order: order, limit: rowsPerPage, offset: page * rowsPerPage },
      });
      setPools(response.data.data);
    } catch (error) {
      console.error('Error fetching pools:', error);
    }
  };

  const fetchTotalPools = async () => {
    try {
      const response = await axios.get(`/api/channel/${channelName}/token-pools/count`);
      setTotalPools(response.data.total_count);
    } catch (error) {
      console.error('Error fetching total pools count:', error);
    }
  };

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  if (error) {
    return <Typography variant="h6" color="error">{error}</Typography>;
  }

  return (
    <Box padding={2}>
      {userInfo && (
        <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 2 }}>
          <Avatar src={userInfo.profile_image_url} sx={{ width: 80, height: 80, marginRight: 2 }} />
          <Typography variant="h4" sx={{ fontWeight: 'bold' }}>
            {userInfo.display_name}'s Token Pools
          </Typography>
        </Box>
      )}
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sortDirection={orderBy === 'token_id' ? order : false}>
                <TableSortLabel
                  active={orderBy === 'token_id'}
                  direction={orderBy === 'token_id' ? order : 'asc'}
                  onClick={() => handleRequestSort('token_id')}
                >
                  Pool ID
                </TableSortLabel>
              </TableCell>
              <TableCell sortDirection={orderBy === 'symbol' ? order : false}>
                <TableSortLabel
                  active={orderBy === 'symbol'}
                  direction={orderBy === 'symbol' ? order : 'asc'}
                  onClick={() => handleRequestSort('symbol')}
                >
                  Token Symbol
                </TableSortLabel>
              </TableCell>
              <TableCell sortDirection={orderBy === 'contract' ? order : false}>
                <TableSortLabel
                  active={orderBy === 'contract'}
                  direction={orderBy === 'contract' ? order : 'asc'}
                  onClick={() => handleRequestSort('contract')}
                >
                  Contract
                </TableSortLabel>
              </TableCell>
              <TableCell sortDirection={orderBy === 'balance' ? order : false}>
                <TableSortLabel
                  active={orderBy === 'balance'}
                  direction={orderBy === 'balance' ? order : 'asc'}
                  onClick={() => handleRequestSort('balance')}
                >
                  Balance
                </TableSortLabel>
              </TableCell>
              <TableCell sortDirection={orderBy === 'rate' ? order : false}>
                <TableSortLabel
                  active={orderBy === 'rate'}
                  direction={orderBy === 'rate' ? order : 'asc'}
                  onClick={() => handleRequestSort('rate')}
                >
                  Rate
                </TableSortLabel>
              </TableCell>
              <TableCell sortDirection={orderBy === 'min_redeem' ? order : false}>
                <TableSortLabel
                  active={orderBy === 'min_redeem'}
                  direction={orderBy === 'min_redeem' ? order : 'asc'}
                  onClick={() => handleRequestSort('min_redeem')}
                >
                  Min Redeem
                </TableSortLabel>
              </TableCell>
              <TableCell sortDirection={orderBy === 'max_redeem' ? order : false}>
                <TableSortLabel
                  active={orderBy === 'max_redeem'}
                  direction={orderBy === 'max_redeem' ? order : 'asc'}
                  onClick={() => handleRequestSort('max_redeem')}
                >
                  Max Redeem
                </TableSortLabel>
              </TableCell>
              <TableCell sortDirection={orderBy === 'cooldown' ? order : false}>
                <TableSortLabel
                  active={orderBy === 'cooldown'}
                  direction={orderBy === 'cooldown' ? order : 'asc'}
                  onClick={() => handleRequestSort('cooldown')}
                >
                  Cooldown
                </TableSortLabel>
              </TableCell>
              <TableCell sortDirection={orderBy === 'is_active' ? order : false}>
                <TableSortLabel
                  active={orderBy === 'is_active'}
                  direction={orderBy === 'is_active' ? order : 'asc'}
                  onClick={() => handleRequestSort('is_active')}
                >
                  Active
                </TableSortLabel>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {pools.length > 0 ? (
              pools.map((pool) => (
                <TableRow key={pool.token_id}>
                  <TableCell>{pool.token_id}</TableCell>
                  <TableCell>{pool.symbol}</TableCell>
                  <TableCell>{pool.contract}</TableCell>
                  <TableCell>{parseFloat(pool.balance).toLocaleString(undefined, { minimumFractionDigits: pool.decimals })}</TableCell>
                  <TableCell>{`${parseFloat(pool.rate).toFixed(2)}%`}</TableCell>
                  <TableCell>{pool.min_redeem}</TableCell>
                  <TableCell>{pool.max_redeem === 0 ? '∞' : pool.max_redeem}</TableCell>
                  <TableCell>{pool.cooldown}</TableCell>
                  <TableCell>
                    <Switch
                      checked={pool.is_active}
                      sx={{
                        pointerEvents: 'none',
                      }}
                    />
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={10} align="center">
                  <Typography variant="body1">No token pools available.</Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        count={totalPools}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        rowsPerPageOptions={[25, 50, 100]}
      />
    </Box>
  );
};

export default TokenPoolPublic;
