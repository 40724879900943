import React, { useState, useEffect, useMemo, useCallback, useRef } from 'react';
import { useForm, Controller } from 'react-hook-form';
import {
  Box,
  Tabs,
  Tab,
  TextField,
  CircularProgress,
  FormControlLabel,
  Switch,
  Typography,
  Tooltip,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@mui/material';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import axios from 'axios';
import DeleteIcon from '@mui/icons-material/Delete';

// Normalizes the setting value based on its data type
const normaliseSettingValue = (value, dataType) => {
  switch (dataType) {
    case 'boolean':
      return value === 'true'; // Convert "true" string to boolean true
    case 'int':
      return value !== null && !isNaN(parseInt(value, 10))
        ? parseInt(value, 10).toString()
        : null;
    case 'float':
      return value !== null && !isNaN(parseFloat(value))
        ? parseFloat(value).toFixed(2)
        : null;
    case 'percent':
      return value !== null && !isNaN(parseFloat(value))
        ? (parseFloat(value) * 100).toFixed(2)
        : null;
    case 'json':
      try {
        const parsedValue = JSON.parse(value);
        return Array.isArray(parsedValue) ? parsedValue : [];
      } catch (e) {
        return [];
      }
    case 'string':
    default:
      return value === null || value === undefined ? null : value;
  }
};

// Generate validation rules based on data type and dependencies
const getValidationRules = (setting, getValues) => {
  switch (setting.setting_data_type) {
    case 'boolean':
      return {};
    case 'int':
      return {
        validate: (value) => {
          if (value === null || value === '') return true; // Allow null or empty
          const numberValue = parseInt(value, 10);
          return (
            (!isNaN(numberValue) && numberValue >= 0) ||
            `${setting.setting_label} must be a valid integer`
          );
        },
      };
    case 'float':
    case 'percent':
      return {
        validate: (value) => {
          if (value === null || value === '') return true;
          const numberValue = parseFloat(value);
          if (isNaN(numberValue) || numberValue < 0) {
            return `${setting.setting_label} must be a valid number`;
          }
          // Check for maximum of 2 decimal places
          const decimalPlaces = value.includes('.') ? value.split('.')[1].length : 0;
          if (decimalPlaces > 2) {
            return `${setting.setting_label} must have at most 2 decimal places`;
          }
          // Specific validation for dig_chance
          if (setting.setting_key === 'dig_chance' && numberValue > 100) {
            return `${setting.setting_label} cannot be above 100%`;
          }
          return true;

        },
      };
    case 'json':
      return {
        validate: (value) => {
          try {
            JSON.parse(value);
            return true;
          } catch (e) {
            return `${setting.setting_label} must be a valid JSON`;
          }
        },
      };
    case 'string':
    default:
      return {};
  }
};

const CustomLabel = ({ label, tooltip, type }) => (
  <Box display="flex" alignItems="center">
    {label}
    {tooltip && (
      <Tooltip title={tooltip}>
        <IconButton size="small" sx={{ ml: 0.5 }}>
          <HelpOutlineIcon sx={{ fontSize: type === 'boolean' ? '1.1rem' : '1.5rem', color: 'white' }} />
        </IconButton>
      </Tooltip>
    )}
  </Box>
);

const Settings = () => {
  const [activeTab, setActiveTab] = useState(null);
  const [settingsData, setSettingsData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [initialValues, setInitialValues] = useState({});
  const [tabLabels, setTabLabels] = useState([]);

  const {
    control,
    reset,
    setValue,
    getValues,
    trigger,
    formState: { errors },
    setError,
    clearErrors,
    watch,
  } = useForm({
    defaultValues: {},
    shouldUnregister: false, // Keep all fields registered even when unmounted
  });

  // Define dependent fields and mutual exclusivity
  const dependentFields = useMemo(
    () => [
      {
        triggerFields: ['dig_template_req', 'dig_template'],
        dependentField: 'dig_template',
        condition: (values) => values.dig_template_req,
        errorMessage: 'Template ID is required when Dig Template is active',
      },
      {
        triggerFields: ['dig_booster', 'dig_booster_template'],
        dependentField: 'dig_booster_template',
        condition: (values) => values.dig_booster,
        errorMessage: 'Booster Template ID is required when Dig Booster is active',
      },
      {
        triggerFields: ['dig_booster', 'dig_booster_percentage'],
        dependentField: 'dig_booster_percentage',
        condition: (values) => values.dig_booster,
        errorMessage: 'Booster Percentage is required when Dig Booster is active',
      },
    ],
    []
  );

  const mutuallyExclusiveGroups = useMemo(
    () => [
      ['chat_chest_command', 'chat_chest_math'],
      // Add more mutually exclusive field pairs here
    ],
    []
  );

  // Validation function for dependent fields
  const validateDependentFields = useCallback(
    (values) => {
      dependentFields.forEach(({ dependentField, condition, errorMessage }) => {
        if (condition(values) && !values[dependentField]) {
          setError(dependentField, { type: 'required', message: errorMessage });
        } else {
          clearErrors(dependentField);
        }
      });
    },
    [dependentFields, setError, clearErrors]
  );

  // Enforce mutual exclusivity between field pairs
  const enforceMutualExclusivity = useCallback(
    (values) => {
      mutuallyExclusiveGroups.forEach(([fieldA, fieldB]) => {
        if (values[fieldA] && values[fieldB]) {
          // Deactivate fieldB if fieldA is activated
          setValue(fieldB, false);
          clearErrors(fieldB);
        }
      });
    },
    [mutuallyExclusiveGroups, setValue, clearErrors]
  );

  // Combined validation function
  const validateFields = useCallback(
    (values) => {
      validateDependentFields(values);
      enforceMutualExclusivity(values);
    },
    [validateDependentFields, enforceMutualExclusivity]
  );

  // Function to update settings
  const updateSettings = useCallback(
    async (settingKey, value, dataType) => {
      let normalizedValue = null;

      // Convert value based on data type
      switch (dataType) {
        case 'boolean':
          normalizedValue = value ? 'true' : 'false'; // Convert boolean to "true" or "false" string
          break;
        case 'int':
          if (value !== null && value !== '' && !isNaN(parseInt(value, 10))) {
            normalizedValue = parseInt(value, 10).toString();
          } else {
            normalizedValue = null;
          }
          break;
        case 'float':
          if (value !== null && value !== '' && !isNaN(parseFloat(value))) {
            normalizedValue = parseFloat(value).toFixed(2);
          } else {
            normalizedValue = null;
          }
          break;
        case 'percent':
          if (value !== null && value !== '' && !isNaN(parseFloat(value))) {
            normalizedValue = (parseFloat(value) / 100).toString();
          } else {
            normalizedValue = null;
          }
          break;
        case 'json':
          normalizedValue = JSON.stringify(value);
          break;
        default:
          normalizedValue = normaliseSettingValue(value, dataType);
          break;
      }

      try {
        await axios.post(
          '/api/channel-settings',
          {
            settings: [
              {
                setting_key: settingKey,
                setting_value: normalizedValue,
              },
            ],
          },
          { withCredentials: true }
        );
      } catch (error) {
        console.error(`Error updating ${settingKey}:`, error);
      }
    },
    [setValue]
  );

  // Fetch settings and initialize form
  useEffect(() => {
    const fetchSettings = async () => {
      try {
        const response = await fetch('/api/channel-settings?setting_type=all_settings', {
          credentials: 'include',
        });
        const result = await response.json();

        if (result.success) {
          const normalisedSettings = result.data.map((setting) => ({
            ...setting,
            setting_value: normaliseSettingValue(
              setting.setting_value,
              setting.setting_data_type
            ),
          }));

          setSettingsData(normalisedSettings);

          const uniqueTypes = [...new Set(normalisedSettings.map((setting) => setting.setting_type))];
          setTabLabels(uniqueTypes);

          const defaultValues = normalisedSettings.reduce((acc, setting) => {
            acc[setting.setting_key] =
              setting.setting_value !== null ? setting.setting_value : '';
            return acc;
          }, {});

          setInitialValues(defaultValues);
          reset(defaultValues);
          validateFields(defaultValues);
        }
      } catch (error) {
        console.error('Error fetching settings:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchSettings();
  }, [reset, validateFields]);

  // Watch for changes and validate accordingly
  useEffect(() => {
    const subscription = watch((values) => {
      validateFields(values);
    });

    return () => subscription.unsubscribe();
  }, [watch, validateFields]);

  // Trigger validation on tab change to validate fields on the new tab
  useEffect(() => {
    trigger(); // Validate all fields when tab changes
  }, [activeTab, trigger]);

  // After fetching settings data and setting tabLabels
  useEffect(() => {
    if (tabLabels.length > 0) {
      const defaultTab = 'chat';

      // Get the hash from the URL (without '#')
      const hash = window.location.hash.substr(1).toLowerCase(); // Remove '#' and make lowercase

      // Find the index of the tab that matches the hash
      let tabIndex = tabLabels.findIndex(
        (label) => label.toLowerCase() === hash
      );

      if (tabIndex >= 0) {
        // If found, set activeTab to that index
        setActiveTab(tabIndex);
      } else {
        // If not found or no hash, find the index of "Chat"
        tabIndex = tabLabels.findIndex(
          (label) => label.toLowerCase() === defaultTab
        );
        setActiveTab(tabIndex >= 0 ? tabIndex : 0);
      }
    }
  }, [tabLabels]);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
    const newHash = tabLabels[newValue];
    window.history.replaceState(null, null, `#${newHash}`);
  };

  // Render your form fields dynamically
  const renderFormFields = (settingType) => {
    if (!settingsData) return null;

    return settingsData
      .filter((setting) => setting.setting_type === settingType)
      .map((setting) => {
        const { setting_key, setting_label, setting_data_type, setting_desc } = setting;
        const validationRules = getValidationRules(setting, getValues);

        // Determine the field type based on data type
        let fieldComponent = null;
        switch (setting_data_type) {
          case 'boolean':
            fieldComponent = (
              <Controller
                key={setting_key}
                name={setting_key}
                control={control}
                render={({ field }) => {
                  const isMutuallyExclusive = mutuallyExclusiveGroups.some((group) =>
                    group.includes(setting_key)
                  );
                  let disabled = false;

                  if (isMutuallyExclusive) {
                    const [fieldA, fieldB] = mutuallyExclusiveGroups.find((group) =>
                      group.includes(setting_key)
                    );
                    disabled = fieldA === setting_key ? watch(fieldB) === true : watch(fieldA) === true;
                  }

                  return (
                    <FormControlLabel
                      control={
                        <Switch
                          checked={!!field.value} // Ensure the value is a boolean
                          onChange={async (e) => {
                            const newValue = e.target.checked;
                            field.onChange(newValue); // Update form value with boolean

                            if (isMutuallyExclusive && newValue) {
                              const [fieldA, fieldB] = mutuallyExclusiveGroups.find((group) =>
                                group.includes(setting_key)
                              );
                              const oppositeField = setting_key === fieldA ? fieldB : fieldA;
                              setValue(oppositeField, false); // Set opposite field to false
                              clearErrors(oppositeField);
                              await updateSettings(oppositeField, false, 'boolean');
                            }

                            await updateSettings(setting_key, newValue, 'boolean');
                          }}
                          disabled={disabled}
                        />
                      }
                      label={<CustomLabel label={setting_label} tooltip={setting_desc} type={setting_data_type} />}
                    />
                  );
                }}
              />
            );
            break;
          case 'int':
          case 'float':
          case 'percent':
          case 'string':
          default:
            fieldComponent = (
              <Controller
                key={setting_key}
                name={setting_key}
                control={control}
                rules={validationRules}
                render={({ field }) => (
                  <TextField
                    {...field}
                    type={
                      setting_data_type === 'int' ||
                      setting_data_type === 'float' ||
                      setting_data_type === 'percent'
                        ? 'text' // Change type to text to remove stepping
                        : 'text'
                    }
                    inputProps={{}} // Remove step to disable stepping
                    label={<CustomLabel label={setting_label} tooltip={setting_desc} type={setting_data_type} />}
                    fullWidth
                    value={field.value !== null ? field.value : ''}
                    error={!!errors[setting_key]}
                    helperText={errors[setting_key]?.message}
                    disabled={
                      dependentFields.some((df) => df.dependentField === setting_key) &&
                      !dependentFields
                        .find((df) => df.dependentField === setting_key)
                        .condition(getValues())
                    }
                    onChange={(e) => {
                      const value = e.target.value;
                      if (setting_data_type === 'int') {
                        if (value === '' || /^\d+$/.test(value)) {
                          setValue(setting_key, value);
                        }
                      } else if (setting_data_type === 'float' || setting_data_type === 'percent') {
                        if (value === '' || /^\d*\.?\d{0,2}$/.test(value)) {
                          setValue(setting_key, value);
                        }
                      } else {
                        setValue(setting_key, value);
                      }
                    }}
                    onBlur={() => {
                      const value = getValues(setting_key);
                      if (value !== null && value !== '') {
                        let formattedValue = value;
                        if (setting_data_type === 'int') {
                          formattedValue = parseInt(value, 10).toString();
                        } else if (setting_data_type === 'float' || setting_data_type === 'percent') {
                          formattedValue = parseFloat(value).toFixed(2);
                          if (setting_data_type === 'percent' && setting_key === 'dig_chance' && parseFloat(formattedValue) > 100) {
                            formattedValue = '100.00';
                          }
                        }
                        setValue(setting_key, formattedValue);
                        updateSettings(setting_key, formattedValue, setting_data_type);
                      } else {
                        setValue(setting_key, '');
                        updateSettings(setting_key, null, setting_data_type);
                      }
                      trigger(setting_key);
                    }}
                  />
                )}
              />
            );
            break;
          case 'json':
            fieldComponent = (
              <Controller
                key={setting_key}
                name={setting_key}
                control={control}
                render={({ field }) => (
                  <JsonTable
                    value={field.value}
                    onChange={(newValue) => {
                      field.onChange(newValue);
                      updateSettings(setting_key, newValue, 'json');
                    }}
                    settingLabel={setting_label}
                    settingDesc={setting_desc}
                  />
                )}
              />
            );
            break;
        }

        return (
          <Box key={setting_key} mb={2}>
            {fieldComponent}
          </Box>
        );
      });
  };

  return (
    <div>
      {loading ? (
        <CircularProgress />
      ) : activeTab === null ? null : (
        <Box>
          {/* Tabs and TabPanels */}
          <Tabs
            value={activeTab}
            onChange={handleTabChange}
            aria-label="Settings Tabs"
            sx={{
              borderBottom: 1,
              borderColor: 'divider',
            }}
          >
            {tabLabels.map((label, index) => (
              <Tab
                key={index}
                label={label}
                sx={{
                  color: '#fff',
                  '&.Mui-selected': {
                    fontWeight: 'bold',
                  },
                }}
              />
            ))}
          </Tabs>

          {tabLabels.map((label, index) => (
            <TabPanel key={index} value={activeTab} index={index}>
              <form>{renderFormFields(label)}</form>
            </TabPanel>
          ))}
        </Box>
      )}
    </div>
  );
};

// Helper component for Tab Panels
const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`settings-tabpanel-${index}`}
      aria-labelledby={`settings-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography component="div">{children}</Typography>
        </Box>
      )}
    </div>
  );
};

// Define the row limit constant for JSON tables
const JSON_TABLE_ROW_LIMIT = 5;

const JsonTable = ({ value, onChange, settingLabel, settingDesc }) => {
  const [rows, setRows] = useState(() => {
    const groupedRows = [];
    const headers = [...new Set(value.map(item => item.header))];
    for (let i = 0; i < value.length; i += headers.length) {
      groupedRows.push(value.slice(i, i + headers.length));
    }
    if (groupedRows.length < JSON_TABLE_ROW_LIMIT && (groupedRows.length === 0 || groupedRows[groupedRows.length - 1].some(cell => cell.value !== ''))) {
      groupedRows.push(headers.map(header => ({ header, value: '', dataType: value.find(item => item.header === header).dataType })));
    }
    return groupedRows;
  });

  useEffect(() => {
    if (rows.length === 0) {
      setRows([[{ header: 'Dig Booster Template ID', value: '', dataType: 'int' }, { header: 'Dig Booster Percentage', value: '', dataType: 'float' }]]);
    }
  }, [rows]);

  useEffect(() => {
  }, [rows]);

  const handleCellChange = (rowIndex, colIndex, newValue) => {
    const updatedRows = [...rows];
    updatedRows[rowIndex][colIndex].value = newValue;
    // If any cell in this row has a value (not blank) and the boolean cell is still "" set the boolean cell to false
    const rowHasData = updatedRows[rowIndex].some(cell => cell.value !== '' && cell.dataType !== 'boolean');
    const booleanCell = updatedRows[rowIndex].find(cell => cell.dataType === 'boolean');
    if (rowHasData && booleanCell && booleanCell.value === '') {
      booleanCell.value = 'false';
    }
    setRows(updatedRows);

    const flattened = updatedRows.flat();
    const filtered = [];
    for (let i = 0; i < flattened.length; i += headers.length) {
      const chunk = flattened.slice(i, i + headers.length);
      if (chunk.some(cell => cell.value !== '')) {
        filtered.push(...chunk);
      }
    }
    onChange(filtered);

    // Add a new row if the current row is populated and the number of rows is less than limit
    if (rowIndex === rows.length - 1 && updatedRows[rowIndex].some(cell => cell.value !== '') && rows.length < JSON_TABLE_ROW_LIMIT) {
      handleAddRow();
    }
  };

  const handleAddRow = () => {
    if (rows.length < JSON_TABLE_ROW_LIMIT) {
      const newRow = rows[0].map(cell => ({ ...cell, value: '' }));
      setRows([...rows, newRow]);
    }
  };

  const handleDeleteRow = (rowIndex) => {
    const updatedRows = [...rows];
    updatedRows.splice(rowIndex, 1);
    if (updatedRows.length === 0 || (updatedRows.length < JSON_TABLE_ROW_LIMIT && updatedRows[updatedRows.length - 1].some(cell => cell.value !== ''))) {
      updatedRows.push(rows[0].map(cell => ({ ...cell, value: '' })));
    }
    setRows(updatedRows);
    onChange(updatedRows.flat());
  };

  const validateInput = (value, dataType) => {
    switch (dataType) {
      case 'int':
        return /^\d*$/.test(value);
      case 'float':
        return /^\d*\.?\d{0,2}$/.test(value);
      case 'boolean':
        return value === 'true' || value === 'false';
      case 'string':
      default:
        return true;
    }
  };

  const headers = rows.length > 0 ? rows[0].map(cell => cell.header) : [];

  return (
    <Box>
      <Box display="flex" alignItems="center">
        <Typography variant="body1">{settingLabel}</Typography>
        <Tooltip title={settingDesc}>
          <IconButton size="small" sx={{ ml: 0.5 }}>
            <HelpOutlineIcon sx={{ fontSize: '1.1rem', color: 'white' }} />
          </IconButton>
        </Tooltip>
      </Box>
      <TableContainer component={Paper} sx={{ width: 'auto', margin: 'auto' }}>
        <Table>
          <TableHead>
            <TableRow>
              {headers.map((header, index) => (
                <TableCell key={index} style={{ fontWeight: 'bold', color: 'white' }}>{header}</TableCell>
              ))}
              <TableCell style={{ fontWeight: 'bold', color: 'white' }}>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, rowIndex) => (
              <TableRow key={rowIndex}>
                {row.map((cell, colIndex) => (
                  <TableCell key={colIndex}>
                    {cell.dataType === 'boolean' ? (
                      <Switch
                        checked={cell.value === 'true'}
                        onChange={(e) => {
                          const updatedRows = [...rows];
                          const wasBlank = updatedRows[rowIndex][colIndex].value === '';
                          if (!e.target.checked && wasBlank) {
                            updatedRows[rowIndex][colIndex].value = ''; // remain blank
                          } else {
                            updatedRows[rowIndex][colIndex].value = e.target.checked ? 'true' : 'false';
                          }
                          setRows(updatedRows);
                        }}
                        onBlur={() => {
                          handleCellChange(rowIndex, colIndex, rows[rowIndex][colIndex].value);
                        }}
                      />
                    ) : (
                      <TextField
                        value={cell.value || ''}
                        onChange={(e) => {
                          const newValue = e.target.value;
                          if (validateInput(newValue, cell.dataType)) {
                            const updatedRows = [...rows];
                            updatedRows[rowIndex][colIndex].value = newValue;
                            setRows(updatedRows);
                          }
                        }}
                        onBlur={(e) => {
                          handleCellChange(rowIndex, colIndex, e.target.value);
                          if (cell.dataType === 'float' && e.target.value !== '') {
                            const floatValue = parseFloat(e.target.value);
                            if (!isNaN(floatValue)) {
                              handleCellChange(rowIndex, colIndex, floatValue.toFixed(2));
                            }
                          }
                        }}
                      />
                    )}
                  </TableCell>
                ))}
                <TableCell>
                  {row.some(cell => cell.value !== '') && (
                    <IconButton onClick={() => handleDeleteRow(rowIndex)} color="error">
                      <DeleteIcon />
                    </IconButton>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default Settings;
