import React, { useState, useEffect } from 'react';
import {
  Table, TableBody, TableHead, Paper, Typography, Box, IconButton, Button, Checkbox, TableContainer, TableCell, TableRow, TablePagination, TableSortLabel, CircularProgress
} from '@mui/material';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import axios from 'axios';

const Leaderboard = () => {
  const [users, setUsers] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [sortOrder, setSortOrder] = useState('desc');
  const [sortBy, setSortBy] = useState('balance');
  const [totalUsers, setTotalUsers] = useState(0);
  const [loading, setLoading] = useState(false);

  // Fetch total user count
  const fetchTotalUsers = async () => {
    try {
      const response = await axios.get('/api/leaderboard/count', { withCredentials: true });
      setTotalUsers(response.data.total_count);
    } catch (error) {
      console.error('Error fetching total users count:', error);
    }
  };

  // Fetch leaderboard data with sorting and pagination
  const fetchUsers = async () => {
    setLoading(true);
    try {
      const response = await axios.get('/api/leaderboard', {
        withCredentials: true,
        params: {
          limit: rowsPerPage,
          offset: page * rowsPerPage,
          sort_by: sortBy === 'lifetimeEarnings' ? 'Lifetime Earnings' : sortBy,
          sort_order: sortOrder,
        },
      });
      setUsers(response.data);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching users:', error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchTotalUsers();
  }, []);

  useEffect(() => {
    fetchUsers();
  }, [page, rowsPerPage, sortBy, sortOrder]);

  // Handle sort request
  const handleRequestSort = (property) => {
    const isAsc = sortBy === property && sortOrder === 'asc';
    setSortOrder(isAsc ? 'desc' : 'asc');
    setSortBy(property);
  };

  // Handle pagination
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Handle CSV export
  const exportToCSV = async () => {
    try {
      let allUsers = [];
      const maxResultsPerPage = 1000;
      const totalPages = Math.ceil(totalUsers / maxResultsPerPage);
      for (let page = 0; page < totalPages; page++) {
        const response = await axios.get('/api/leaderboard', {
          withCredentials: true,
          params: {
            limit: maxResultsPerPage,
            offset: page * maxResultsPerPage,
            sort_by: sortBy === 'lifetimeEarnings' ? 'Lifetime Earnings' : sortBy,
            sort_order: sortOrder,
          },
        });
        allUsers = allUsers.concat(response.data);
      }

      const headers = ['Twitch ID', 'Username', 'Wallet', 'Balance', 'Total Spent', 'Lifetime Earnings', 'Total Messages', 'Total Sub Streak', 'Total Bits', 'Total Gifted'];

      const rows = allUsers.map(user => [
        user['twitch_id'],
        user['username'],
        user['wallet'],
        user['balance'],
        user['total_spent'],
        user['lifetime_earnings'],
        user['total_messages'],
        user['total_streak'],
        user['total_bits'],
        user['total_gifted'],
      ]);

      const csvContent = [
        headers.join(","),
        ...rows.map(row => row.join(",")),
      ].join("\n");

      const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `leaderboard_${Date.now()}.csv`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error exporting to CSV:', error);
    }
  };

  return (
    <Box sx={{ padding: '16px' }}>
    <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
      <Box>
        <Typography variant="h5">
          Leaderboard
        </Typography>
        <Typography variant="body1" color="#A0A0A0">
          View your top loyalty token holders, spenders and chatters.
        </Typography>
      </Box>
      <Button
        variant="contained"
        onClick={exportToCSV}
        disabled={users.length === 0}
      >
        <FileDownloadOutlinedIcon />
        &nbsp;Export
      </Button>
    </Box>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <TableSortLabel
                  active={sortBy === 'username'}
                  direction={sortBy === 'username' ? sortOrder : 'asc'}
                  onClick={() => handleRequestSort('username')}
                >
                  Username
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={sortBy === 'wallet'}
                  direction={sortBy === 'wallet' ? sortOrder : 'asc'}
                  onClick={() => handleRequestSort('wallet')}
                >
                  Wallet
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={sortBy === 'balance'}
                  direction={sortBy === 'balance' ? sortOrder : 'asc'}
                  onClick={() => handleRequestSort('balance')}
                >
                  Balance
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={sortBy === 'total_spent'}
                  direction={sortBy === 'total_spent' ? sortOrder : 'asc'}
                  onClick={() => handleRequestSort('total_spent')}
                >
                  Total Spent
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={sortBy === 'lifetime_earnings'}
                  direction={sortBy === 'lifetime_earnings' ? sortOrder : 'asc'}
                  onClick={() => handleRequestSort('lifetime_earnings')}
                >
                  Lifetime Earnings
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={sortBy === 'total_messages'}
                  direction={sortBy === 'total_messages' ? sortOrder : 'asc'}
                  onClick={() => handleRequestSort('total_messages')}
                >
                  Total Messages
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={sortBy === 'total_streak'}
                  direction={sortBy === 'total_streak' ? sortOrder : 'asc'}
                  onClick={() => handleRequestSort('total_streak')}
                >
                  Total Sub Streak
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={sortBy === 'total_bits'}
                  direction={sortBy === 'total_bits' ? sortOrder : 'asc'}
                  onClick={() => handleRequestSort('total_bits')}
                >
                  Total Bits
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={sortBy === 'total_gifted'}
                  direction={sortBy === 'total_gifted' ? sortOrder : 'asc'}
                  onClick={() => handleRequestSort('total_gifted')}
                >
                  Total Gifted
                </TableSortLabel>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <TableRow>
                <TableCell colSpan={6} align="center">
                  <CircularProgress />
                </TableCell>
              </TableRow>
            ) : (
              users.map((user) => (
                <TableRow key={user['twitch_id']}>
                  <TableCell>{user['username']}</TableCell>
                  <TableCell>
                    <a
                      href={`https://atomichub.io/profile/wax-mainnet/${user['wallet']}?blockchain=wax-mainnet&order=desc&sort=transferred#inventory`}
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ color: '#FFFFFF', textDecoration: 'underline' }}
                    >
                      {user['wallet']}
                    </a>
                  </TableCell>
                  <TableCell>{Number(user['balance']).toLocaleString()}</TableCell>
                  <TableCell>{Number(user['total_spent']).toLocaleString()}</TableCell>
                  <TableCell>{Number(user['lifetime_earnings']).toLocaleString()}</TableCell>
                  <TableCell>{Number(user['total_messages']).toLocaleString()}</TableCell>
                  <TableCell>{Number(user['total_streak']).toLocaleString()}</TableCell>
                  <TableCell>{Number(user['total_bits']).toLocaleString()}</TableCell>
                  <TableCell>{Number(user['total_gifted']).toLocaleString()}</TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <TablePagination
        component="div"
        count={totalUsers}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        rowsPerPageOptions={[25, 50, 100]}
      />
    </Box>
  );
};

export default Leaderboard;